import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Header from './../../header'
import Footer from './../../footer'
import s from './style.module.scss'
import common from '../../../../../locale/common'
import SupportApp from '../../a_components/SpecificationSupportApp'

class Specifications extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
    }
  }

  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title =
      common['sparrow_' + local] + ' - ' + common['specifications_' + local]
  }
  //隐藏Goose购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }
  render() {
    const { hideGooseChoice } = this.state
    const { location } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.specifications}
        ref="Pspecifications"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <Header
          location={location}
          language={this.state.language}
          path={path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <div className={s.sparrow_box}>
          <div className={s.Gparam}>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="exterior" />
              </div>
              <div className={s.cell}>
                <div className={s.sparrow_sizeImg}>
                  <div className={s.top_side}>
                    <div className={s.left_left}>
                      <div className={s.left_img}></div>
                      <div className={s.left_bottom}></div>
                      <div className={s.bottom_num}>
                        <FormattedMessage id="sp_size_long" />
                      </div>
                    </div>
                    <div className={s.left_right}></div>
                    <div className={s.right_num}>
                      <FormattedMessage id="sp_size_short" />
                    </div>
                  </div>
                  <div className={s.top_top}>
                    <div className={s.top_img}></div>
                    <div className={s.top_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="sp_size_height" />
                    </div>
                  </div>
                </div>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_size" />
                    </span>
                    <FormattedMessage id="sp_size" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_color" />
                    </span>
                    <FormattedMessage id="sp_color" />
                  </li>
                </ul>
              </div>
            </div>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="sp_env" />
              </div>
              <div className={s.cell}>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="sp_temp" />
                    </span>
                    <FormattedMessage id="sp_temp_range" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="sp_humi" />
                    </span>
                    <FormattedMessage id="sp_humi_range" />
                  </li>
                </ul>
                {/* <p>* 请勿长时间在湿度超过 90% 的环境下使用。</p> */}
              </div>
            </div>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="p_specifications" />
              </div>
              <div className={s.cell}>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_name" />
                    </span>
                    <FormattedMessage id="sp_gateway" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_weight" />
                    </span>
                    <FormattedMessage id="sp_weight" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="only_wifi" />
                    </span>
                    <FormattedMessage id="sp_wifi_type" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="only_bluetooth" />
                    </span>
                    <FormattedMessage id="sp_ble" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="sp_power_in" />
                    </span>
                    <FormattedMessage id="sp_power" />
                  </li>
                </ul>
              </div>
            </div>
            <div className={s.row}>
                <div className={s.name}>
                    <FormattedMessage id="other" />
                  </div>
                  <div className={s.cell}>
                    <ul>
                      <li style={{ display: "flex", alignItems: "center"}}>
                        <span className={s.type}>
                          <FormattedMessage id="support_app" />
                        </span>

                        <SupportApp
                          supportApps={["mi_home", "plus"]}
                        ></SupportApp>
                      </li>
                </ul>
              </div>
            </div>
            <div className={s.bottom}>
              <div className={s.name}>
                <FormattedMessage id="p_pack" />
              </div>
              <div className={s.cell}>
                <div className={s.bottomimg}>
                  <div className={s.gateway_left}>
                    <div className={s.gateway_img}></div>
                    <div className={s.gateway_name}>
                      <FormattedMessage id="sparrow" />
                    </div>
                  </div>
                  <div className={s.mannual}>
                    <div className={s.mannual_img}></div>
                    <div className={s.mannual_name}>
                      <FormattedMessage id="p_instructions" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let specifications = connect(mapStateToProps)(Specifications)

export default specifications
