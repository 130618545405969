import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sparam.module.scss'
import SupportApp from '../../a_components/SpecificationSupportApp'

class Sparam extends React.PureComponent {
  render() {
    return (
      <div className={s.box}>
        <div className={s.Sparam}>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="exterior" />
            </div>
            <div className={s.cell}>
              <div className={s.sizeImg}>
                <div className={s.top_side}>
                  <div className={s.left_left}>
                    <div className={s.left_img}></div>
                    <div className={s.left_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="dany_long" />
                    </div>
                  </div>
                  <div className={s.left_right}></div>
                  <div className={s.right_num}>
                    <FormattedMessage id="dany_height" />
                  </div>
                </div>
                <div className={s.top_top}>
                  <div className={s.top_img}></div>
                  <div className={s.top_bottom}></div>
                  <div className={s.bottom_num}>
                    <FormattedMessage id="dany_short" />
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_size" />
                  </span>
                  <FormattedMessage id="dany_size" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_color" />
                  </span>
                  <FormattedMessage id="dany_color" />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="range" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_temp" />
                  </span>
                  <FormattedMessage id="dany_temp_range" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_hum" />
                  </span>
                  <FormattedMessage id="dany_humi_range" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="co2" />
                  </span>
                  <FormattedMessage id="dany_co2_range" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="pm25" />
                  </span>
                  <FormattedMessage id="dany_pm25_range" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="pm10" />
                  </span>
                  <FormattedMessage id="dany_pm10_range" />
                </li>
              </ul>
              <p className={s.snow_annotation}>
                <FormattedMessage id="under_ninty" />
              </p>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="p_specifications" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="dany_name" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="dany_model" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="dany_weight" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="screen_size" />
                  </span>
                  <FormattedMessage id="dany_screen" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="only_bluetooth" />
                  </span>
                  <FormattedMessage id="dany_ble" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="only_wifi" />
                  </span>
                  <FormattedMessage id="dany_wifi" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_battery" />
                  </span>
                  <FormattedMessage id="dany_battery" />
                </li>
                <li>
                  <span className={s.type}><FormattedMessage id="usb_port" /></span>
                  <FormattedMessage id="usb_port_value" />
                </li>
                <li>
                  <span className={s.type}><FormattedMessage id="rated_input" /></span>
                  <FormattedMessage id="rated_input_value" />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
          <div className={s.name}>
              <FormattedMessage id="other" />
            </div>
            <div className={s.cell}>
              <ul>
                <li style={{ display: "flex",alignItems: "center" }}>
                  <span className={s.type}>
                    <FormattedMessage id="support_app" />
                  </span>

                  <SupportApp
                    supportApps={["home_kit", 'mi_home', 'plus']}
                  ></SupportApp>
                </li>
          </ul>
        </div>
      </div>
          <div className={s.pack}>
            <div className={s.name}>
              <FormattedMessage id="p_pack" />
            </div>
            <div className={s.cell} style={{ paddingLeft: 0 }}>
              <ul className={s.packWrap}>
                <li>
                  <i className={s.pack_1} />
                  <FormattedMessage id="dany_name" />
                </li>
                <li>
                  <i className={s.pack_2} />
                  <FormattedMessage id="p_instructions" />
                </li>
                <li>
                  <i className={s.pack_3} />
                  <FormattedMessage id="s_usb_charge" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SParam = connect(mapStateToProps)(Sparam)

export default SParam
