import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import s from './Sparam.module.scss'
import SupportApp from '../../a_components/SpecificationSupportApp'
class Sparam extends React.PureComponent {
  render() {
    return (
      <div className={s.box}>
        <div className={s.Sparam}>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="exterior" />
            </div>
            <div className={s.cell}>
              <div className={s.sizeImg}>
                <div className={s.top_side}>
                  <div className={s.left_left}>
                    <div className={s.left_img}></div>
                    <div className={s.left_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="dk2_long" />
                    </div>
                  </div>
                </div>
                <div className={s.top_top}>
                  <div className={s.top_img}></div>
                  <div className={s.top_bottom}></div>
                  <div className={s.bottom_num}>
                    <FormattedMessage id="dk2_height" />
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_size" />
                  </span>
                  <FormattedMessage id="dk2_size" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_color" />
                  </span>
                  <FormattedMessage id="dk2_color" />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="range" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_temp" />
                  </span>
                  <FormattedMessage id="dk2_temp_range" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_hum" />
                  </span>
                  <FormattedMessage id="dk2_humi_range" />
                </li>
              </ul>
              <p className={s.snow_annotation}>
                <FormattedMessage id="under_ninty" />
              </p>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="p_specifications" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="dk2_name" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="dk2_model" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="dk2_weight" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="screen_size" />
                  </span>
                  <FormattedMessage id="dk2_screen" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="mp_ble_type" />
                  </span>
                  <FormattedMessage id="dk2_ble" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_battery" />
                  </span>
                  <FormattedMessage id="dk2_battery" />
                </li>

              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="other" />
            </div>
            <div className={s.cell}>
              <ul>
                

                <li style={{display:'flex'}}>
                  <span className={s.type} style={{display: 'flex', alignItems:' center'}}>
                    <FormattedMessage id="support_app" />
                  </span>
                  
                  <SupportApp supportApps={['mi_home', 'plus']}></SupportApp>
                </li>

                <li style={{ display:'flex', alignItems: 'flex-start', marginBottom: 0 }}>
                  <span className={s.type}>
                    <FormattedMessage id="remote_access" values={{ br: '' }} />
                  </span>
                  <span>
                    <FormattedMessage
                      id="mi_app_need_1"
                      values={{ br: <br /> }}
                    />
                    <Link
                      to="/bluetooth-gateway"
                      style={{ color: '#000', textDecoration: 'underline' }}
                    >
                      <FormattedMessage id="sparrow" />
                    </Link>
                    <FormattedMessage id="end" />
                    <br />
                    <span style={{ marginTop: 8, display: 'block' }}>
                      <FormattedMessage id="mi_app_need_2" />
                      <Link
                        to="/plus"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="cg_app" />
                      </Link>
                      <FormattedMessage
                        id="mi_app_need_3"
                        values={{ br: <br /> }}
                      />
                      <Link
                        to="/bluetooth-gateway"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="sparrow" />
                      </Link>
                      <FormattedMessage id="end" />
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className={s.pack}>
            <div className={s.name}>
              <FormattedMessage id="p_pack" />
            </div>
            <div className={s.cell}>
              <ul className={s.packWrap}>
                <li>
                  <i className={s.pack_1} />
                  <FormattedMessage id="dk2_name" />
                </li>
                <li>
                  <i className={s.pack_2} />
                  <FormattedMessage id="dk2_pack_wall" />
                </li>
                <li>
                  <i className={s.pack_3} />
                  <FormattedMessage id="dk2_pack_desk" />
                </li>
                <li>
                  <i className={s.pack_4} />
                  <FormattedMessage id="dk2_pack_battery" />
                </li>
                <li>
                  <i className={s.pack_5} />
                  <FormattedMessage id="p_instructions" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SParam = connect(mapStateToProps)(Sparam)

export default SParam
