import React, { Component } from 'react';

import s from './airPrivacy.module.scss'

class iotPrivacy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // init top hide
            display: false
        };
    }
    componentDidMount() {
        document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    }
    render() {
        return (
            <div className={s.iotPrivacy}>
                <div>
                <p><b className={s.pt18}>PRIVACY POLICY</b></p>
                <p>
                    <b className={s.pt12}>Updated on February 8, 2025.</b>
                </p>
                <p>
                    <b className={s.pt12}>Welcome to the Qingping IoT app.</b>
                </p>
                <p>
                    When you use this app, your phone model information and IP address will be automatically sent to the app's server to retrieve and display app content and functionality properly.
                </p>
                <p>
                    If you register an account, the phone number or email address used for registration, or the ID from a third-party login method, will be recorded by the app's server to identify your user account.
                </p>
                <p>
                    We will also record the identification details, readings, and settings of the Qingping devices you add or share within the app, as well as information related to teams you create or join. This is necessary for the normal functionality of the app.
                </p>
                <p>
                    When connecting Qingping devices via the app, the app may request Bluetooth and "Nearby Devices" permissions (earlier versions of Android may classify this as "Location" permission) to scan for nearby Bluetooth devices. Once the relevant operation is completed, the app will immediately stop using these permissions. We do not record your geographical location or information about nearby devices.
                </p>
                <p className={s.pt12}>This app uses push SDKs to send notifications:</p>
                <ul>
                    <li>Google Play Version: Google Push.</li>
                    <li>Other Versions: Xiaomi Push, Huawei Push, OPPO Push, Vivo Push.</li>
                </ul>
                <p>
                The app also integrates the Alipay SDK to provide a convenient payment function, and the Tencent Bugly SDK to improve application stability and help us analyze and repair application crashes.</p>
                <p>
                    If you encounter issues while using the app or Qingping devices, you can submit logs via "More &gt; Contact Us" (or "More &gt; Advanced &gt; Get Device Logs"). Relevant logs will be recorded to assist in diagnosing the issue.
                </p>
                <p>To delete your data from the app's server, you can delete your account.</p>
                <p>
                    If the app's functionality changes, the type of information we collect may also change. In such cases, this Privacy Policy will be updated accordingly.
                </p>
                <p>
                    If you have any questions, feedback, or suggestions regarding this Privacy Policy or your personal information, please contact us at{' '}
                    <a href="mailto:support@qingping.co">support@qingping.co</a>. We will respond as soon as possible.
                </p>
                <p>Qingping Technology (Beijing) Co., Ltd.</p>
                </div>
            </div>
        );
    }
}

export default iotPrivacy;
