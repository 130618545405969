import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import s from './Nav.module.scss'

class Nav extends React.PureComponent {
  toTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
      isHide: true,
      purchaseHide: true,
      secondCur: [
        s.hide,
        s.hide,
        s.hide,
        s.hide,
        s.hide,
        s.hide,
        s.hide,
        s.hide,
        s.hide,
      ],
      reload: true,
      showShopList: false,
    }
  }

  componentDidMount() { }

  handleClick(Keypress, e) {
    e.stopPropagation()
    if (Keypress) {
      this.refs.qrcode.style.paddingBottom = '0px'
      this.refs.qrcode.style.paddingRight = '0px'
    } else {
      this.refs.qrcode.style.paddingBottom = '200px'
      this.refs.qrcode.style.paddingRight = '200px'
    }
    this.setState({
      isHide: !this.state.isHide,
    })
  }

  purchaseClick(e) {
    e.stopPropagation()
    this.setState({
      purchaseHide: !this.state.purchaseHide,
    })
  }

  gooseClick(e) {
    e.stopPropagation()
    this.props.hideGooseChoiceFun(false, e)
  }

  handlePosition(url) {
    const oneItemArr = ['wall-socket-thermometer']
    const twoItemsArr = [
      'bluetooth-clock',
      'co2-temp-rh-monitor',
      'air-monitor-2'
    ]
    const fourItemsArr = ['motion-light-sensor']
    const eightItemsArr = ['temp-rh-monitor']
    
    if (oneItemArr.indexOf(url) > -1) {
      return {
        top: 10,
        left: -3,
      }
    } else if (twoItemsArr.indexOf(url) > -1) {
      return {
        top: -20,
        left: 44,
      }
    } else if (eightItemsArr.indexOf(url) > -1) {
      return {
        top: -155,
        left: 86,
      }
    } else if (fourItemsArr.indexOf(url) > -1) {
      return {
        top: -50,
        left: 36,
        // top: -40,
        // left: 12,
      }
      
    }else if(['door-window-sensor'].indexOf(url) > -1) {
      return{
        top: -20,
        left: 20,
      }
    }else {
      return {
        top: 0,
        // left: -5
        left: 46,
      }
    }
  }

  render() {
    // const countryCode = sessionStorage.getItem('countryCode')
    const ifCN = sessionStorage.getItem('countryCode') === 'CN'
    const { locale } = this.props
    const { reload, showShopList } = this.state
    const path = this.props.path
    const pathArr = this.props.path.split('/')
    // const search = this.props.search;
    // let secondSub = s.second;
    let nav = s.nav
    let airSecondSub = s.hide
    let thirdSub = s.third
    const firstCur = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]
    const secondCur = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]
    const thirdCur = [null, null, null]
    let url = 'temp-rh-monitor'
    let pName = <FormattedMessage id="goose_lite" />
    let buyUrl = ifCN
      ? 'https://detail.tmall.com/item.htm?id=591748428692'
      : 'https://www.amazon.com/dp/B08N6BL434/'
    let buyText = <FormattedMessage id="buy" />
    let buyClick
    const logoName = s.logo
    let bgName = s.hide
    let purchaseName = s.purchaseHide
    let isAir
    let iosDown
    let anDown
    let googleDown
    if (path === '/') {
      // secondSub = s.hide;
      nav = s.navFixed
      firstCur[0] = s.active
      thirdSub = s.hide
    }
    if (path === '/plus' || path === '/air') {
      // secondSub = s.hide;
      thirdSub = s.hide
      airSecondSub = s.airSecond
      isAir = true
      iosDown =
        'https://itunes.apple.com/cn/app/%E9%9D%92%E8%90%8D%E7%A9%BA%E6%B0%94/id1344636968?mt=8'
      anDown = 'https://qing.cleargrass.com/download/plus'
      googleDown =
        'https://play.google.com/store/apps/details?id=com.cleargrass.app.air'
    }
    if (path === '/lee-guitars/app') {
      thirdSub = s.hide
      airSecondSub = s.airSecond
      isAir = false
      iosDown = 'https://apps.apple.com/cn/app/id1481163925'
      anDown = 'https://qing.cleargrass.com/download/magpie'
      googleDown =
        'https://play.google.com/store/apps/details?id=com.cleargrass.app.magpie'
    }
    if (
      pathArr[1] === 'mi-temp-rh-monitor' ||
      pathArr[1] === 'temp-rh-monitor' ||
      pathArr[1] === 'temp-rh-monitor-pro-s' ||
      pathArr[1] === 'temp-rh-monitor-pro-e' ||
      pathArr[1] === 'temp-rh-monitor-lite' ||
      pathArr[1] === 'lee-guitars-thermo-hygrometer' ||
      pathArr[1] === 'wall-socket-thermometer'
    ) {
      firstCur[1] = s.active
      if (this.props.navPosition) {
        thirdSub = s.thirdSubPosition
      }
    } else if (pathArr[1] === 'plus' || pathArr[1] === 'air') {
      firstCur[3] = s.active
      if (this.props.navPosition) {
        airSecondSub = s.airSecondSubPosition
      }
    } else if (
      pathArr[1] === 'air-monitor' ||
      pathArr[1] === 'air-monitor-2' ||
      pathArr[1] === 'air-monitor-lite' ||
      pathArr[1] === 'co2-temp-rh-monitor'
    ) {
      firstCur[2] = s.active
      if (this.props.navPosition) {
        thirdSub = s.thirdSubPosition
      }
    } else if (
      pathArr[1] === 'door-window-sensor' ||
      pathArr[1] === 'motion-light-sensor'
    ) {
      firstCur[8] = s.active
      if (this.props.navPosition) {
        thirdSub = s.thirdSubPosition
      }
    } else if (pathArr[1] === 'qingpingiot') {
      firstCur[4] = s.active
      if (this.props.navPosition) {
        airSecondSub = s.airSecondSubPosition
      }
    } else if (pathArr[1] === 'store') {
      firstCur[5] = s.active
    } else if (pathArr[1] === 'mi-multifunction-air-monitor') {
      // secondSub = s.hide;
      thirdSub = s.hide
    } else if (pathArr[1] === 'bluetooth-gateway') {
      firstCur[6] = s.active
      if (this.props.navPosition) {
        thirdSub = s.thirdSubPosition
      }
    } else if (
      pathArr[1] === 'bluetooth-alarm-clock' ||
      pathArr[1] === 'bluetooth-clock'
    ) {
      firstCur[7] = s.active
      if (this.props.navPosition) {
        thirdSub = s.thirdSubPosition
      }
    }

    if (pathArr[1] === 'air-monitor') {
      secondCur[4] = s.active
      secondCur[5] = null
      secondCur[11] = null
      secondCur[13] = null
      url = 'air-monitor'
      pName = <FormattedMessage id="snow" />
      buyUrl = 'https://detail.tmall.com/item.htm?id=592463435043'
    } else if (pathArr[1] === 'air-monitor-2') {
      secondCur[13] = s.active
      secondCur[4] = null
      secondCur[5] = null
      secondCur[11] = null
      url = 'air-monitor-2'
      pName = <FormattedMessage id="sw2" />
      buyUrl = 'https://detail.tmall.com/item.htm?id=592463435043'
    } else if (pathArr[1] === 'air-monitor-lite') {
      secondCur[4] = null
      secondCur[5] = s.active
      secondCur[11] = null
      secondCur[13] = null
      url = 'air-monitor-lite'
      pName = <FormattedMessage id="dany_name" />
      buyUrl = ifCN
        ? 'https://detail.tmall.com/item.htm?id=635133749492'
        : 'https://www.amazon.com/dp/B092HK4BB1'
    } else if (pathArr[1] === 'co2-temp-rh-monitor') {
      secondCur[4] = null
      secondCur[5] = null
      secondCur[11] = s.active
      secondCur[13] = null
      url = 'co2-temp-rh-monitor'
      pName = <FormattedMessage id="ph_co2_name_value" />
      buyUrl = ifCN
        ? 'https://detail.tmall.com/item.htm?id=635133749492'
        : 'https://www.amazon.de/dp/B0C6XWMRZ6'
    } else if (pathArr[1] === 'door-window-sensor') {
      secondCur[6] = s.active
      secondCur[7] = null
      url = 'door-window-sensor'
      pName = <FormattedMessage id="hodor_name" values={{ br: '' }} />
      buyUrl = 'https://detail.tmall.com/item.htm?id=636588241018'
    } else if (pathArr[1] === 'motion-light-sensor') {
      secondCur[6] = null
      secondCur[7] = s.active
      url = 'motion-light-sensor'
      pName = <FormattedMessage id="parrot_name" values={{ br: '' }} />
      buyUrl = 'https://detail.tmall.com/item.htm?id=642300517425'
      buyText = <FormattedMessage id="buy" />
    } else if (pathArr[1] === 'mi_temp_rh_monitor') {
      secondCur[2] = s.active
      secondCur[1] = null
      secondCur[0] = null
      url = 'mi-temp-rh-monitor'
      pName = <FormattedMessage id="duck" />
      buyUrl = 'https://item.mi.com/product/7303.html'
    } else if (pathArr[1] === 'bluetooth-gateway') {
      // secondCur[3] = s.active;
      // secondCur[4] = null;
      url = 'bluetooth-gateway'
      pName = <FormattedMessage id="sparrow" />
      buyUrl = ifCN
        ? 'https://detail.tmall.com/item.htm?id=606216176161'
        : 'https://www.amazon.com/dp/B08N6BL434/'
    } else if (pathArr[1] === 'bluetooth-alarm-clock') {
      secondCur[8] = s.active
      secondCur[9] = null
      url = 'bluetooth-alarm-clock'
      pName = <FormattedMessage id="dove" />
      buyUrl = ifCN
        ? 'https://detail.tmall.com/item.htm?id=618768031024'
        : 'https://www.amazon.com/dp/B08N6BL434/'
    } else if (pathArr[1] === 'bluetooth-clock') {
      secondCur[9] = s.active
      secondCur[8] = null
      url = 'bluetooth-clock'
      pName = <FormattedMessage id="chicken" />
      buyUrl = ifCN
        ? 'https://detail.tmall.com/item.htm?id=644288144909'
        : 'https://www.amazon.com/dp/B08N6BL434/'
    } else if (pathArr[1] === 'temp-rh-monitor-lite') {
      secondCur[3] = s.active
      secondCur[2] = null
      secondCur[1] = null
      secondCur[0] = null
      secondCur[10] = null
      secondCur[12] = null
      buyUrl = ifCN
        ? 'https://detail.tmall.com/item.htm?id=632003208384'
        : 'https://www.amazon.com/dp/B08N6BL434/'
      url = 'temp-rh-monitor-lite'
      pName = <FormattedMessage id="duck2" />
      buyText = <FormattedMessage id="buy" />
    } else if (pathArr[1] === 'temp-rh-monitor-pro-s') {
      secondCur[3] = null
      secondCur[2] = null
      secondCur[1] = s.active
      secondCur[0] = null
      secondCur[10] = null
      secondCur[12] = null
      buyUrl = 'https://detail.tmall.com/item.htm?id=601534027348'
      url = 'temp-rh-monitor-pro-s'
      pName = <FormattedMessage id="pheasant" />
      buyText = <FormattedMessage id="buy" />
      // buyClick = e => this.purchaseClick(e);
    } else if (pathArr[1] === 'temp-rh-monitor-pro-e') {
      secondCur[3] = null
      secondCur[2] = null
      secondCur[1] = null
      secondCur[0] = null
      secondCur[10] = s.active
      secondCur[12] = null
      buyUrl = 'https://detail.tmall.com/item.htm?id=651176963505'
      url = 'temp-rh-monitor-pro-e'
      pName = <FormattedMessage id="frog" values={{ br: '' }} />
      buyText = <FormattedMessage id="buy" />
      // buyClick = e => this.purchaseClick(e);
    } else if (pathArr[1] === 'lee-guitars-thermo-hygrometer') {
      secondCur[0] = null
      secondCur[1] = null
      secondCur[2] = s.active
      secondCur[3] = null
      secondCur[10] = null
      secondCur[12] = null
      url = 'lee-guitars-thermo-hygrometer'
      pName = <FormattedMessage id="magpie" values={{ br: '' }} />
      buyUrl = ifCN
        ? 'https://detail.tmall.com/item.htm?id=627234761816'
        : 'https://www.amazon.com/dp/B08N6BL434/'
    } else if (pathArr[1] === 'temp-rh-monitor') {
      secondCur[0] = s.active
      secondCur[1] = null
      secondCur[2] = null
      secondCur[3] = null
      secondCur[10] = null
      secondCur[12] = null
    } else if (pathArr[1] === 'wall-socket-thermometer') {
      secondCur[0] = null
      secondCur[1] = null
      secondCur[2] = null
      secondCur[3] = null
      secondCur[10] = null
      secondCur[12] = s.active
      url = 'wall-socket-thermometer'
      pName = <FormattedMessage id="gecko_name" />
      buyUrl = 'https://detail.tmall.com/item.htm?id=731211875493'
    } else if (pathArr[1] === 'mi-air-monitor' || 'store') {
      thirdSub = s.hide
    }
    if (pathArr[2] === 'specifications') {
      thirdCur[1] = s.active
    } else {
      thirdCur[0] = s.active
    }
    if (!this.state.isHide) {
      bgName = s.bg
    }
    if (!this.state.purchaseHide) {
      purchaseName = s.purchaseShow
    }
    const ifTwOrUs = locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
    const allMalls = [
      {
        name: 'mall_value_1',
        link: ifTwOrUs
          ? 'https://www.amazon.com/stores/page/C2CB7673-1A0F-43CD-BC1C-46B86535AB12'
          : 'https://cleargrass.tmall.com/',
      },
      {
        name: 'mall_value_2',
        link: ifTwOrUs
          ? 'https://www.amazon.de/stores/page/64309CC1-3751-47EC-BD21-CB3CE42542B3'
          : 'https://www.amazon.com/stores/page/C2CB7673-1A0F-43CD-BC1C-46B86535AB12',
      },
      {
        name: 'mall_value_3',
        link: ifTwOrUs
          ? 'https://cleargrass.tmall.com/'
          : 'https://www.amazon.de/stores/page/64309CC1-3751-47EC-BD21-CB3CE42542B3',
      },
    ]

    const goodsLink = {
      'temp-rh-monitor': [
        {
          name: 'mall_value_1_T',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B0BQ6235KH'
            : 'https://detail.tmall.com/item.htm?id=695482721170&skuId=5110652835001',
        },
        {
          name: 'mall_value_2_T',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B0BQ6235KH/'
            : 'https://www.amazon.com/dp/B0BQ6235KH/',
        },
        {
          name: 'mall_value_3_T',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=695482721170&skuId=5110652835001'
            : 'https://www.amazon.de/dp/B0BQ6235KH/',
        },
        // {
        //   name: 'mall_value_1_H',
        //   link: ifTwOrUs
        //     ? 'https://www.amazon.com/dp/B08N64HC6B/'
        //     : 'https://detail.tmall.com/item.htm?id=624676310772',
        // },
        // {
        //   name: ifTwOrUs ? 'mall_value_3_H' : 'mall_value_2_H',
        //   link: ifTwOrUs
        //     ? 'https://detail.tmall.com/item.htm?id=624676310772'
        //     : 'https://www.amazon.com/dp/B08N64HC6B/',
        // },
        {
          name: 'mall_value_1_M',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B08N6BL434/'
            : 'https://detail.tmall.com/item.htm?id=591748428692',
        },
        {
          name: 'mall_value_2_M',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B099ZK3XYW'
            : 'https://www.amazon.com/dp/B08N6BL434/',
        },
        {
          name: 'mall_value_3_M',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=591748428692'
            : 'https://www.amazon.de/dp/B099ZK3XYW',
        },
      ],
      'temp-rh-monitor-lite': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B08NJMLFPZ'
            : 'https://detail.tmall.com/item.htm?id=632003208384',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B099MLXPL1'
            : 'https://www.amazon.com/dp/B08NJMLFPZ',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=632003208384'
            : 'https://www.amazon.de/dp/B099MLXPL1',
        },
      ],
      'lee-guitars-thermo-hygrometer': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B08NCXYV4G'
            : 'https://detail.tmall.com/item.htm?id=627234761816',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B099ZCJH17'
            : 'https://www.amazon.com/dp/B08NCXYV4G',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=627234761816'
            : 'https://www.amazon.de/dp/B099ZCJH17',
        },
      ],
      'bluetooth-alarm-clock': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B08NJ3TBNH'
            : 'https://detail.tmall.com/item.htm?id=618768031024',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B099Z9Q4P1'
            : 'https://www.amazon.com/dp/B08NJ3TBNH',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=618768031024'
            : 'https://www.amazon.de/dp/B099Z9Q4P1',
        },
      ],
      'bluetooth-clock': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B094QY5QM9'
            : 'https://detail.tmall.com/item.htm?id=644288144909',
        },
        {
          name: ifTwOrUs ? 'mall_value_3' : 'mall_value_2',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=644288144909'
            : 'https://www.amazon.com/dp/B094QY5QM9',
        },
      ],
      'air-monitor': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B0967WP9HS'
            : 'https://detail.tmall.com/item.htm?id=592463435043',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B0967WP9HS'
            : 'https://www.amazon.com/dp/B0967WP9HS',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=592463435043'
            : 'https://www.amazon.de/dp/B0967WP9HS',
        },
      ],
      'air-monitor-2': [
        // {
        //   name: 'mall_value_temporary',
        //   link: 'https://detail.tmall.com/item.htm?id=777501386204',
        // },
        // {
        //   name: 'mall_value_2',
        //   link: ifTwOrUs
        //     ? 'https://www.amazon.de/dp/B0CZ886B8N'
        //     : 'https://www.amazon.com/dp/B0CZ886B8N',
        // },
        // {
        //   name: 'mall_value_3',
        //   link: ifTwOrUs
        //     ? 'https://detail.tmall.com/item.htm?id=777501386204'
        //     : 'https://www.amazon.de/dp/B0CZ886B8N',
        // },
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B0967WP9HS'
            : 'https://detail.tmall.com/item.htm?id=777501386204',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B0967WP9HS'
            : 'https://www.amazon.com/dp/B0967WP9HS',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=777501386204'
            : 'https://www.amazon.de/dp/B0967WP9HS',
        },
      ],
      'air-monitor-lite': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B092HK4BB1'
            : 'https://detail.tmall.com/item.htm?id=635133749492',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B092HK4BB1'
            : 'https://www.amazon.com/dp/B092HK4BB1',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=635133749492'
            : 'https://www.amazon.de/dp/B092HK4BB1',
        },
      ],
      'temp-rh-monitor-pro-s': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B0BTBQ6Z51'
            : 'https://detail.tmall.com/item.htm?id=601534027348',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B0BTBQ6Z51'
            : 'https://www.amazon.com/dp/B0BTBQ6Z51',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=601534027348'
            : 'https://www.amazon.de/dp/B0BTBQ6Z51',
        },
      ],
      'co2-temp-rh-monitor': [
        // daa
        {
          name: ifTwOrUs ? 'mall_value_2' : 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B0C6XWMRZ6'
            : 'https://detail.tmall.com/item.htm?id=719646205355',
        },
        {
          name: ifTwOrUs ? 'mall_value_1' : 'mall_value_2',
          link: 'https://www.amazon.com/dp/B0C6XWMRZ6',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=719646205355'
            : 'https://www.amazon.de/dp/B0C6XWMRZ6',
        },
        //111
      ],
      // 'wall-socket-thermometer': [
      //   {
      //     name: ifTwOrUs ? 'mall_value_3' : 'mall_value_1',
      //     link: 'https://detail.tmall.com/item.htm?id=731211875493',
      //   },
      // ],
      'bluetooth-gateway': [
        {
          name: 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B08Y8G2S4Y'
            : 'https://detail.tmall.com/item.htm?id=606216176161',
        },
        {
          name: 'mall_value_2',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B099NC1279'
            : 'https://www.amazon.com/dp/B08Y8G2S4Y',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=606216176161'
            : 'https://www.amazon.de/dp/B099NC1279',
        },
      ],
      'door-window-sensor': [
        {
          name: ifTwOrUs ? 'mall_value_2' : 'mall_value_1',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B097121MBM'
            : 'https://detail.tmall.com/item.htm?id=636588241018',
        },
        {
          name: 'mall_value_3',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=636588241018'
            : 'https://www.amazon.de/dp/B097121MBM',
        },
      ],
      'motion-light-sensor': [
        {
          name: 'mall_value_1_T',
          link: ifTwOrUs
            ? 'https://www.amazon.com/dp/B0978FRSFL'
            : 'https://detail.tmall.com/item.htm?id=717364573346',
        },
        {
          name: 'mall_value_2_T',
          link: ifTwOrUs
            ? 'https://www.amazon.de/dp/B0978FRSFL'
            : 'https://www.amazon.com/dp/B0978FRSFL',
        },
        {
          name: 'mall_value_3_T',
          link: ifTwOrUs
            ? 'https://detail.tmall.com/item.htm?id=717364573346'
            : 'https://www.amazon.de/dp/B0978FRSFL',
        },
        {
          name: ifTwOrUs ? 'mall_value_3_M' : 'mall_value_1_M',
          link: 'https://detail.tmall.com/item.htm?id=642300517425',
        },
      ],
    }
    return (
      <div className={nav} ref="nav">
        <div className={s.first_sub}>
          <ul
            className={s.items}
            onMouseLeave={() => {
              showShopList &&
                this.setState({
                  showShopList: false,
                })
            }}
          >
            <li>
              <div className={logoName}>
                <NavLink to="/" className={firstCur[0]}>
                  <i />
                </NavLink>
              </div>
            </li>
            <li
              className={locale.indexOf('US') > -1 ? s.tempHum_en : s.tempHum}
              style={{ margin: 0, paddingLeft: 30, paddingRight: 30 }}
            >
              <span className={firstCur[1]}>
                <FormattedMessage id="temp_rh" />
                <i className={s.line} />
              </span>
              <div
                className={
                  locale.indexOf('US') > -1 ? s.tempHumHide_en : s.tempHumHide
                }
              >
                <ul>
                  <li className={secondCur[0] + ' ' + s.limit_width}>
                    <NavLink to="/temp-rh-monitor/overview">
                      <i className={s.goose} />
                      <FormattedMessage id="goose_lite" />
                    </NavLink>
                  </li>
                  <li className={secondCur[3] + ' ' + s.limit_width}>
                    <NavLink to="/temp-rh-monitor-lite/overview">
                      <i className={s.duck2} />
                      <FormattedMessage id="duck2" />
                    </NavLink>
                  </li>
                  <li className={secondCur[1] + ' ' + s.limit_width}>
                    <NavLink to="/temp-rh-monitor-pro-s/overview">
                      <i className={s.lora} />
                      <FormattedMessage id="pheasant" />
                    </NavLink>
                  </li>
                  <li className={secondCur[10] + ' ' + s.limit_width}>
                    <NavLink to="/temp-rh-monitor-pro-e/overview">
                      <i className={s.frog} />
                      <FormattedMessage id="frog" values={{ br: '' }} />
                    </NavLink>
                  </li>
                  <li className={secondCur[12] + ' ' + s.limit_width}>
                    <NavLink to="/wall-socket-thermometer/overview">
                      <i className={s.gecko} />
                      <FormattedMessage id="gecko_name" />
                    </NavLink>
                  </li>
                  <li className={secondCur[2] + ' ' + s.limit_width}>
                    <NavLink to="/lee-guitars-thermo-hygrometer/overview">
                      <i className={s.duck} />
                      <FormattedMessage id="magpie" values={{ br: '' }} />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            <li className={locale.indexOf('US') > -1 ? s.airs_en : s.airs}>
              <span className={firstCur[7]}>
                <FormattedMessage id="clock" />
                <i className={s.line} />
              </span>
              <div
                className={locale.indexOf('US') > -1 ? s.airHide_en : s.airHide}
              >
                <ul>
                  <li
                    className={secondCur[8]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/bluetooth-alarm-clock/overview">
                      <i className={s.dove} />
                      <FormattedMessage id="dove" />
                    </NavLink>
                  </li>
                  <li
                    className={secondCur[9]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/bluetooth-clock/overview">
                      <i className={s.chicken} />
                      <FormattedMessage id="chicken" />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            <li className={locale.indexOf('US') > -1 ? s.airs_en : s.airs}>
              <span className={firstCur[2]}>
                <FormattedMessage id="air_name" />
                <i className={s.line} />
              </span>
              <div
                className={locale.indexOf('US') > -1 ? s.airHide_en : s.airHide}
              >
                <ul>
                  {/* snow1 */}
                  {/* <li
                    className={secondCur[4]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/air-monitor/overview">
                      <i className={s.snow} />
                      <FormattedMessage id="snow" />
                    </NavLink>
                  </li> */}
                  <li
                    className={secondCur[13]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/air-monitor-2/overview">
                      <i className={s.snow2} />
                      <FormattedMessage id="sw2" />
                    </NavLink>
                  </li>
                  <li
                    className={secondCur[5]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/air-monitor-lite/overview">
                      <i className={s.dany} />
                      <FormattedMessage id="dany_name" />
                    </NavLink>
                  </li>
                  <li
                    className={secondCur[11]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/co2-temp-rh-monitor/overview">
                      <i className={s.lora} />
                      <FormattedMessage id="ph_co2_name_value" />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className={locale.indexOf('US') > -1 ? s.airs_en : s.airs}>
              <span className={firstCur[8]}>
                <FormattedMessage id="sensor_name" />
                <i className={s.line} />
              </span>
              <div
                className={locale.indexOf('US') > -1 ? s.airHide_en : s.airHide}
              >
                <ul>
                  <li
                    className={secondCur[6]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/door-window-sensor/overview">
                      <i className={s.hodor} />
                      <FormattedMessage id="hodor_name" values={{ br: ' ' }} />
                    </NavLink>
                  </li>
                  <li
                    className={secondCur[7]}
                    style={{ paddingLeft: 30, paddingRight: 30 }}
                  >
                    <NavLink to="/motion-light-sensor/overview">
                      <i className={s.parrot} />
                      <FormattedMessage id="parrot_name" values={{ br: ' ' }} />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className={firstCur[6]}>
              <span>
                <NavLink to="/bluetooth-gateway/overview">
                  <FormattedMessage id="gateway" />
                  <i className={s.line} />
                </NavLink>
              </span>
            </li>

            <li className={firstCur[3]}>
              <span>
                <NavLink to="/plus">
                  <FormattedMessage id="cg_app" />
                </NavLink>
                <i className={s.line} />
              </span>
            </li>
            <li className={firstCur[4]}>
              <span>
                <a
                  href="https://qingpingiot.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="qing_ping_iot" />
                </a>
                <i className={s.line} />
                {/* <NavLink to="/qingpingiot">
                  <FormattedMessage id="qing_ping_iot" />
                                </NavLink>
                <i className={s.line} /> */}
              </span>
            </li>
            <li className={firstCur[5]}>
              <span className={s.store}>
                <a
                  href="https://developer.qingping.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="developer" />
                </a>
                <i className={s.line} />
              </span>
            </li>
            <li
              className={firstCur[5]}
              onMouseOver={(e) => {
                this.setState({
                  showShopList: true,
                })
              }}
            >
              <span className={s.store}>
                <FormattedMessage id="store" />
                <i className={s.line} />
              </span>
            </li>
            <div
              className={s.shopArrow}
              onMouseOver={(e) => {
                this.setState({
                  showShopList: true,
                })
              }}
            >
              <div style={{ height: 65, width: 28, marginTop: -46 }}></div>
              <div
                className={s.wrapMall}
                name="wrapMall"
                style={{ display: showShopList ? 'inline-block' : 'none' }}
              >
                <div className={s.mallBox}>
                  {allMalls.map((item, index) => {
                    return (
                      <a
                        className={s.mallItem}
                        key={item.name}
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          this.setState({
                            reload: !reload,
                          })
                          const code =
                            item.link.indexOf('amazon.com') > -1
                              ? 'US'
                              : item.link.indexOf('amazon.de') > -1
                                ? 'EU'
                                : 'CN'
                          sessionStorage.setItem('countryCode', code)
                        }}
                      >
                        <FormattedMessage id={item.name} />
                      </a>
                    )
                  })}
                </div>
                <div style={{ height: 16 }}></div>
              </div>
            </div>
          </ul>
          <div className={s.buy}>
            <a
              href="https://item.mi.com/product/7303.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="buy" />
            </a>
          </div>
        </div>
        <div className={airSecondSub}>
          <div className={s.airSecondBox}>
            <div className={isAir ? s.airLogo : s.magpieLogo}>
              <NavLink to={isAir ? '/plus' : '/lee-guitars/app'}>
                <i />
                <FormattedMessage id={isAir ? 'cleargass_app' : 'cg_magpie'} />
              </NavLink>
            </div>
            <div className={s.airRightMenu}>
              <div
                className={
                  locale.indexOf('US') > -1
                    ? s.ios_en
                    : locale.indexOf('TW') > -1
                      ? s.ios_tw
                      : s.ios
                }
              >
                <a href={iosDown} target="_blank" rel="noopener noreferrer">
                  <i />
                </a>
              </div>
              <div
                className={
                  locale.indexOf('US') > -1
                    ? s.android_en
                    : locale.indexOf('TW') > -1
                      ? s.android_tw
                      : s.android
                }
              >
                <a href={anDown} target="_blank" rel="noopener noreferrer">
                  <i />
                </a>
              </div>
              <div className={s.google}>
                <a href={googleDown} target="_blank" rel="noopener noreferrer">
                  <i />
                </a>
              </div>
              {/* <div className={s.wechat} onClick={e => this.handleClick(false, e)}>
                <div className={bgName} onClick={e => this.handleClick(true, e)} />
                <div className={isAir ? s.qrcode : s.magpieCode} ref="qrcode" onClick={e => this.handleClick(false, e)} />
              </div> */}
              <div className={s.wechat}>
                <div className={bgName} />
                <div className={isAir ? s.qrcode : s.magpieCode} />
              </div>
            </div>
          </div>
        </div>
        <div className={thirdSub}>
          <ul>
            <li className={thirdCur[0]} ref="nav_1">
              <NavLink to={`/${url}/overview`} onClick={() => this.toTop()}>
                {pName}
              </NavLink>
              <i className={s.line} />
            </li>
            <li className={thirdCur[1]} ref="nav_2">
              <NavLink
                to={`/${url}/specifications`}
                onClick={() => this.toTop()}
              >
                <FormattedMessage id="specifications" />
              </NavLink>
              <i className={s.line} />
            </li>
            {
              <li
                className={s.subBuy}
                onMouseOver={(e) => {
                  goodsLink[pathArr[1]] && this.gooseClick(e)
                }}
              >
                {goodsLink[pathArr[1]] ? (
                  <div className={s.buy_a}>{buyText}</div>
                ) : (
                  <a
                    className={s.buy_a}
                    href={buyUrl}
                    onClick={buyClick}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {buyText}
                  </a>
                )}
              </li>
            }
            {goodsLink[pathArr[1]] && (
              <div className={s.shopArrow}>
                <div style={{ height: 75, width: 28, marginTop: 0 }}></div>
                <div
                  className={s.wrapMall}
                  name="wrapMall"
                  style={
                    !this.props.hideGooseChoice && true
                      ? {
                        display: this.props.hideGooseChoice
                          ? 'none'
                          : 'inline-block',
                        top: this.handlePosition(pathArr[1]).top,
                        left: this.handlePosition(pathArr[1]).left,
                      }
                      : {
                        top: this.handlePosition(pathArr[1]).top,
                        left: this.handlePosition(pathArr[1]).left,
                      }
                  }
                >
                  <div
                    className={s.mallBox}
                    style={{
                      width:
                        pathArr[1] === 'temp-rh-monitor' ||
                          pathArr[1] === 'motion-light-sensor'
                          ? 200
                          : 130,
                    }}
                  >
                    {goodsLink[pathArr[1]].map((item, index) => {
                      return (
                        <a
                          className={s.mallItem}
                          key={item.name}
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            this.setState({
                              reload: !reload,
                            })
                            const code =
                              item.link.indexOf('amazon.com') > -1
                                ? 'US'
                                : item.link.indexOf('amazon.de') > -1
                                  ? 'EU'
                                  : 'CN'
                            sessionStorage.setItem('countryCode', code)
                          }}
                        >
                          <FormattedMessage id={item.name} />
                        </a>
                      )
                    })}
                  </div>
                  <div style={{ height: 16 }}></div>
                </div>
              </div>
            )}
          </ul>
        </div>
        <div style={{ height: 0, opacity: 0 }}>{Number(reload)}</div>
        <div className={purchaseName}>
          <div className={s.purchaseBg} onClick={buyClick} />
          <i />
          <span>
            请发送邮件至{' '}
            <a
              href="mailto:bd@qingping.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              bd@qingping.co
            </a>
            ，<br />
            或致电李先生 189 1187 7866 获取报价。
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let nav = connect(mapStateToProps)(Nav)

export default nav
