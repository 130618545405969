import React, { Component } from "react";

import s from "./airPrivacy.module.scss";

class iotPrivacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // init top hide
      display: false,
    };
  }
  componentDidMount() {
    document.getElementsByTagName("html")[0].style.fontSize = 41.4 + "px";
  }
  render() {
    return (
      <div className={s.iotPrivacy}>
        <div>
           <p>
              <b className={s.pt18}>隐私政策</b>
            </p>
            <p>
              <b className={s.pt12}>更新于 2025 年 2 月 8 日。</b>
            </p>
            <p>
              <b className={s.pt12}>欢迎使用青萍物联 app。</b>
            </p>
          <p>
            当您使用本 app 时，您的手机机型信息和 IP 地址会被自动发送到 app
            的服务器，以便正常获取并显示 app 的内容和功能。
          </p>
          <p>
            如您注册账号，您用于注册的手机号码或电子邮箱地址，或第三方登录方式的
            ID 将被 app 的服务器记录，用于辨识您的用户身份。
          </p>
          <p>
            我们还将记录您在 app
            中添加或共享的青萍设备的识别信息、读数和设置，以及在 app
            内创建或加入的团队的相关信息。这是您正常使用本 app 的功能所必需的。
          </p>
          <p>
            在您使用本 app 连接青萍设备时，app
            可能会请求手机的蓝牙权限。在完成相关操作后，app
            会立即停止使用相关权限。
          </p>
          <p>本应用还集成了 Alipay SDK 以提供便捷的支付功能，并通过腾讯 Bugly 收集崩溃日志，以便开发团队快速定位和解决问题。</p>
          <p>
            如您在使用 app 或青萍设备时遇到问题，您可在“更多 {">"}{" "}
            联系我们”（或“更多 {">"} 高级 {">"} 获取设备 Log”）中提交 app
            或青萍设备的 log，为了诊断问题，相关 log 将被记录。
          </p>
          <p>如要在本 app 的服务器上删除您的数据，您可以销毁您的账号。</p>
          <p>
            如果本 app
            功能发生变化，我们收集的信息可能会发生变化，届时我们会更新此隐私协议。
          </p>
          <p>
            如您对本协议或您的个人信息有任何疑问、意见或建议，请随时发送邮件至{" "}
            <a href="mailto:support@qingping.co">support@qingping.co</a>{" "}
            与我们联系，我们将尽快为您解答。
          </p>
          <p>青萍科技（北京）有限公司</p>
        </div>
      </div>
    );
  }
}

export default iotPrivacy;
