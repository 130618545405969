import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Sparam.module.scss'
import { Link } from 'react-router-dom'
import SupportApp from '../../a_components/SpecificationSupportApp'

class Sparam extends Component{
  render() {
    const { locale } = this.props
    return(
      <div className={s.box}>
        <div className={s.Sparam}>
          <div className={s.row}>
            <div className={s.name}><FormattedMessage id="exterior" /></div>
            <div className={s.cell}>
              <div className={s.sizeImg}>
                <div className={s.front_style}>
                  <div className={s.front_img}></div>
                  <div className={s.front_line}></div>
                  <div className={s.front_number}>
                    <FormattedMessage id="mp_size_short" />
                  </div>
                </div>
                <div className={s.side_line}></div>
                <div className={s.side_number}>
                <FormattedMessage id="mp_size_long" />
                </div>
                <div className={s.side_style}>
                  <div className={s.side_img}></div>
                  <div className={s.border_line}></div>
                  <div className={s.border_number}>
                  <FormattedMessage id="mp_size_border" />
                  </div>
                </div>
              </div>
              <ul>
                <li><span className={s.type}><FormattedMessage id="p_size" /></span>
                <FormattedMessage id="mp_size" /></li>
                <li><span className={s.type}><FormattedMessage id="p_color" /></span>
                <FormattedMessage id="mp_color" /></li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}><FormattedMessage id="range" /></div>
            <div className={s.cell}>
              <ul>
                <li><span className={s.type}>
                  <FormattedMessage id="mp_temp_range_label" />
                  </span>
                  <FormattedMessage id="p_temp_value" />
                  </li>
                <li><span className={s.type}>
                <FormattedMessage id="mp_humi_range_label" />
                  </span>
                  <FormattedMessage id="p_hum_value" />
                  </li>
              </ul>
              <p className={s.snow_annotation}>
              <FormattedMessage id="under_ninty" />
              </p>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}><FormattedMessage id="p_specifications" /></div>
            <div className={s.cell}>
              <ul>
                <li><span className={s.type}>
                <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="magpie"  values={{br:''}} />
                  </li>
                <li><span className={s.type}>
                <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="mp_model" />
                  </li>
                <li><span className={s.type}>
                <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="mp_real_weight" />
                  </li>
                <li><span className={s.type}>
                  <FormattedMessage id="screen_size" />
                  </span>
                  <FormattedMessage id="mp_screen_size_value" />
                  </li>
                <li><span className={s.type}>
                  <FormattedMessage id="mp_ble_type" />
                  </span>
                  <FormattedMessage id="p_wifi_value" />
                  </li>
                <li><span className={s.type}>
                  <FormattedMessage id="p_battery" />
                  </span>
                  <FormattedMessage id="mp_battery_value" />
                  </li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="other_specs" />
            </div>
            <div className={s.cell}>
              <ul>
                <li style={{ display: "flex" }}>
                  <span className={s.type}>
                    <FormattedMessage id="support_app" />
                  </span>

                  <SupportApp
                    supportApps={["magpie", "plus", "mi_home",]}
                  ></SupportApp>
                </li>

                <li
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: 0,
                  }}
                >
                  <span className={s.type}>
                    <FormattedMessage id="remote_access" values={{ br: "" }} />
                  </span>

                  <span>
                    <FormattedMessage id="mi_app_need_2" />
                    <Link
                      to="/lee-guitars/app"
                      style={{ color: " #000", textDecoration: "underline" }}
                    >
                      <FormattedMessage id="mp_product_name" />
                    </Link>
                    {locale.indexOf('US') > -1? ' or ' : '、' }
                    <Link
                      to="/plus"
                      style={{ color: "#000", textDecoration: "underline" }}
                    >
                      <FormattedMessage id="cg_app" />
                    </Link>&nbsp;
                    {locale.indexOf('US') > -1? <FormattedMessage id="app" /> : '' }
                    
                    <FormattedMessage
                      id="mi_app_need_3"
                      values={{ br: <br /> }}
                    />
                    <Link
                      to="/bluetooth-gateway"
                      style={{ color: "#000", textDecoration: "underline" }}
                    >
                      <FormattedMessage id="sparrow" />
                    </Link>
                    <FormattedMessage id="end" />
                    <br />
                    <span style={{ marginTop: 8, display: "block" }}>
                      <FormattedMessage
                        id="mi_app_need_1"
                        values={{ br: <br /> }}
                      />
                      <Link
                        to="/bluetooth-gateway"
                        style={{ color: "#000", textDecoration: "underline" }}
                      >
                        <FormattedMessage id="sparrow" />
                      </Link>
                      <FormattedMessage id="end" />
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className={s.pack}>
            <div className={s.name}><FormattedMessage id="p_pack" /></div>
            <div className={s.cell}>
              <div className={s.pack_1}>
                <div className={s.pack1_img}></div>
                <FormattedMessage id="magpie_enter" values={{br: <br />}} />
              </div>
              <div className={s.pack_wrap}>
                <div className={s.pack_2}>
                  <div className={locale.indexOf('US') > -1 ? s.pack2_img_en : (locale.indexOf('TW') > -1 ? s.pack2_img_tw : s.pack2_img)}></div>
                  <FormattedMessage id="p_instructions" />
                </div>
                <div className={s.pack_3}>
                  <div className={s.pack3_img}></div>
                  <FormattedMessage id="mp_battery_value" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let SParam = connect(mapStateToProps)(Sparam);

export default SParam;

