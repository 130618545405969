import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from './../header'
import Footer from './../footer'
import s from './allPrivacy.module.scss'
import common from '../../../../locale/common'
class AllPrivacy extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      local: 'cn',
      position: false,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    this.setState({ local: local })
    document.title = common['privacy_' + local]

    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US'
        break
      case 'zh':
        browserLanguage = 'zh-CN'
        break
      default:
        browserLanguage = 'en-US'
    }
    this.setState({
      language: browserLanguage,
    })
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const textRect = this.refs.privacy.getBoundingClientRect()
    // console.log(textRect.top);
    if (textRect.top < -50 && this.refs.privacy) {
      if(this.state.position) return
      this.setState({
        position: true,
      })
    } else {
      if(!this.state.position) return
      this.setState({
        position: false,
      })
    }
  }
  render() {
    const { location } = this.props
    const { local } = this.state
    const path = this.props.match.path
    const cnHtml = (
      <div>
        <p className={s.title}>
          <b>隐私政策</b>
        </p>
        <p>
          我们的隐私政策已于 2025 年 1 月 16 日。
          </p>
        <p >欢迎访问青萍官网 qingping.co。</p>

          <p>当您访问本网站时，您的浏览器信息和 IP 地址会被自动发送到网站的服务器，以便获取并显示网页内容。</p>

          <p>如果您在本网站购物，则可能需要注册账号。注册完成后，您的注册信息将会被服务器记录，以便提供相关服务。</p>

          <p>本网站使用 cookie 记录您的语言选择和登录状态。</p>

          <p>如果网站功能发生变化，我们收集的信息可能会发生变化，届时我们会更新此隐私协议。</p>
        <p>
          <span>青萍科技（北京）有限公司</span>
        </p>
      </div>
    )
    const enHtml = (
      <div>
      <p className={s.title}>
        <b>Privacy Policy</b>
      </p>
      <p>
        Updated on January 16, 2025.
      </p>
      <p>Welcome to the Qingping official website: qingping.co.</p>

      <p>When you visit this site, your browser information and IP address are automatically sent to the server to retrieve and display webpage content.</p>

      <p>If you shop on the website, you may need to register an account. Once registration is complete, your registration information will be recorded by the server to provide the relevant services.</p>

      <p>This website uses cookies to record your language preferences and login status.</p>

      <p>If the website functionality changes, the type of information we collect may also change. In such cases, we will update this Privacy Policy accordingly.</p>
      <p>
        <span>Qingping Technology (Beijing) Co., Ltd.</span>
      </p>
    </div>

    )
    const twHtml = (
      <div>
        <p className={s.title}>
          <b>隱私政策</b>
        </p>
        <p>
          我們的隱私政策已於 2025 年 1 月 16 日更新。
        </p>

        <p >歡迎訪問青萍官網 qingping.co。</p>

        <p>當您訪問本網站時，您的瀏覽器信息和 IP 地址會被自動發送到網站的服务器，以便獲取並顯示網頁內容。</p>

        <p>如果您在本網站購物，則可能需要註冊帳號。註冊完成後，您的註冊信息將會被服务器記錄，以便提供相關服務。</p>

        <p>本網站使用 cookie 記錄您的語言選擇和登錄狀態。</p>

        <p>如果網站功能發生變化，我們收集的信息可能會發生變化，屆時我們會更新此隱私協議。</p>
        <p>
          <span>青萍科技（北京）有限公司</span>
        </p>
      </div>

    )
    return (
      <div className={s.box}>
        <Header
          location={location}
          language={this.state.language}
          path={path}
          navPosition={this.state.position}
        />

        <div className={s.AllPrivacy} ref="privacy">
          {local === 'cn' ? cnHtml : local === 'us' ? enHtml : twHtml}
        </div>

        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let Allprivacy = connect(mapStateToProps)(AllPrivacy)

export default Allprivacy
