import React, { Component } from 'react'

import EnSPrivacy from './en'
import ZhPrivacy from './zh'
import ZhHantPrivacy from './zh-Hant'
const params = new URLSearchParams(window.location.search);
const theme = params.get('theme');
class Pprivacy extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      language: 'zh',
      theme
    }
  }
  componentDidMount() {
    console.log(this.props, 'this.props of index.jsx')
    if (this.props.location.search.indexOf('en') > -1) {
      this.setState({
        language: 'en',
      })
    } else if (this.props.location.search.indexOf('ant') > -1) {
      this.setState({
        language: 'zh-Hant',
      })
    } else {
      this.setState({
        language: 'zh',
      })
    }


    function applyTheme(isDarkMode) {
      document.body.style.backgroundColor = isDarkMode ? '#000000' : '#ffffff';
      document.body.style.color = isDarkMode ? '#ffffff' : '#000000';
      setTimeout(() => {
        document.querySelectorAll('a').forEach(a => {
          a.style.color = isDarkMode ? '#ffffff' : '#000000';
          a.style.textDecoration = 'underline';
        });
      },1000)
    }
    
    const isDarkMode = theme 
      ? theme === 'dark' 
      : window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    applyTheme(isDarkMode);

  }

  render() {
    const { language } = this.state

    return (
      <div>
        {language === 'zh-Hant' ? (
          <ZhHantPrivacy />
        ) : language === 'en' ? (
          <EnSPrivacy aColor={theme === 'dark'  ? '#ffffff' : '#000000'}/>
        ) : (
          <ZhPrivacy />
        )}
      </div>
    )
  }
}

export default Pprivacy
