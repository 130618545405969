import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import s from "./index.module.scss";

const appConfig = {
  home_kit: {
    logoClass: s.home_logo,
    titleId: "family",
  },
  mi_home: {
    logoClass: s.mi_logo,
    titleId: "mi_home",
  },
  plus: {
    logoClass: s.plus_logo,
    titleId: "cg_app",
    link: "/plus",
  },
  iot: {
    logoClass: s.iot_logo,
    titleId: "qing_ping_iot",
    link: "/iot",
    redirect: true,
  },
  magpie: {
    logoClass: s.magpie_logo,
    titleId: "cg_magpie",
    link: "/lee-guitars/app",
  },
};

const SupportApp = ({ supportApps }) => {
  return (
    <div className={s.support}>
      {supportApps.map((appKey) => {
        const app = appConfig[appKey];
        if (!app) return null;

        const content = (
          <div className={s.item} key={appKey}>
            <div className={app.logoClass}></div>
            <div className={s.logo_title}>
              <FormattedMessage id={app.titleId} />
            </div>
          </div>
        );

        return app.redirect ? (
          <a href={app.link} target="_blank" key={appKey}>
            {content}
          </a>
        ) : app.link ? (
          <Link
            to={app.link}
            key={appKey}
            style={{ color: "#000", textDecoration: "none" }}
          >
            {content}
          </Link>
        ) : (
          content
        );
      })}
    </div>
  );
};

export default SupportApp;
