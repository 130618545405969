import React, { Component } from 'react'

import s from './airPrivacy.module.scss'

class iotPrivacy extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            // init top hide
            display: false,
        }
    }
    componentDidMount() {
        document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    }
    render() {
        return (
            <div className={s.iotPrivacy}>
             <div>
                <p>
                    <b className={s.pt18}>隱私策略</b>
                </p>
                <p>
                    <b className={s.pt12}>更新於 2025 年 2 月 8 日。</b>
                </p>
                <p>
                    <b className={s.pt12}>歡迎使用青萍物聯 app。</b>
                </p>
                <p>
                    當您使用本 app 時，您的手機機型資訊和 IP 位址會自動發送至 app 的伺服器，以正常獲取並顯示 app 的內容和功能。
                </p>
                <p>
                    若您註冊帳號，您用於註冊的手機號碼或電子郵件地址，或第三方登入方式的 ID，將被 app 的伺服器記錄，用於辨識您的使用者身份。
                </p>
                <p>
                    我們還將記錄您在 app 中新增或共享的青萍設備識別資訊、讀數和設定，以及在 app 內建立或加入的團隊相關資訊。這是您正常使用本 app 的功能所必需的。
                </p>
                <p>
                    在您使用本 app 連接青萍設備時，為了掃描周圍的藍牙設備，app 可能會請求藍牙、「附近的設備」權限（早期 Android 將此權限歸類為「位置資訊」）。在完成相關操作後，app 會立即停止使用相關權限。我們不會記錄您的地理位置和附近設備的資訊。
                </p>
                <p className={s.pt12}>本 app 使用推送 SDK 來發送通知：</p>
                <ul>
                    <li>Google Play 版：Google 推送。</li>
                    <li>其他版本：小米推送、華為推送、OPPO 推送、Vivo 推送。</li>
                </ul>
                <p>本應用還集成了 Alipay SDK 以提供便捷的支付功能，並通過騰訊 Bugly 收集崩潰日誌，以便開發團隊快速定位和解決問題。</p>
                <p>
                    若您在使用 app 或青萍設備時遇到問題，您可在「更多 &gt; 聯絡我們」（或「更多 &gt; 進階 &gt; 獲取設備 Log」）中提交 app 或青萍設備的 log，相關 log 將被記錄以協助診斷問題。
                </p>
                <p>如需從 app 伺服器刪除您的數據，您可以銷毀您的帳號。</p>
                <p>
                    若本 app 的功能發生變更，我們收集的資訊可能會有所變動，屆時我們會更新本隱私政策。
                </p>
                <p>
                    若您對本政策或您的個人資訊有任何疑問、意見或建議，請隨時發送郵件至{' '}
                    <a href="mailto:support@qingping.co">support@qingping.co</a> 與我們聯繫，我們將儘快為您解答。
                </p>
                <p>青萍科技（北京）有限公司</p>
                </div>
            </div>
        )
    }
}

export default iotPrivacy
