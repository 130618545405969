import supportMi from "./en-US/support-mi"

const en = {
  qingping: 'Qingping',
  temp_rh: 'Temp & RH Monitor',
  temp_rh_press: 'Temp and RH Monitor',
  clock: 'Clock',
  air_name: 'Air Monitor',
  gateway: 'Gateway',
  cg_app: 'Qingping+',
  qing_ping_iot: 'Qingping IoT',
  qing_ping_iot_m: 'Qingping IoT App',
  developer: 'Developer',
  index_developer: 'Developer Platform',
  store: 'Shop',
  download: 'Download',

  magpie: 'LEE GUITARS Bluetooth {br}Thermo-Hygrometer',
  magpie_simple: 'LEE GUITARS',
  magpie_enter: 'LEE GUITARS {br} Thermo-Hygrometer',
  grasp_guitar: 'Grasp Guitar with Built-in Magnetics',
  cute_smart: 'Cute and Smart',
  mp_battery_value: 'CR2032 button cell',

  cg_magpie: 'Lee Guitars',
  magpie_prd: 'Works with LEE GUITARS Bluetooth Thermo-Hygrometer',

  more: 'Learn more',

  goose: 'Qingping Temp & RH Monitor',
  goose_lite: 'Qingping Temp & RH Monitor',
  h_version: 'H Version',
  m_version: 'M Version',
  t_version: 'T Version',
  t_version_new: 'T',

  pheasant: 'Qingping Temp & RH Barometer Pro S',
  pheasant_s: 'Qingping Temp & RH {br}Barometer Pro S',
  s_first_title: 'Take Your Breathing Seriously',

  goose_screen: 'E-ink display',
  support: 'Desktop stand',
  wall: 'Wall sticker',
  user: '30 days historical data storage',

  duck2_support: 'Works with Mi Home',
  hodor_monitor: 'Monitor your door/window',

  frog_support_1: 'IP55 protection',
  frog_support_2: 'Long standby time',
  frog_support_3: 'Optional AUX probe',
  frog_support_3_1: 'Optional AUX probe',
  frog_support_4: 'Wi-Fi/NB-IoT/LoRa',

  l_support: 'Wi-Fi/NB-IoT/LoRa',
  l_battery: 'Rechargeable lithium battery',
  l_user: 'Super long standby time',

  dove: 'Qingping Bluetooth Alarm Clock',
  button_less: 'Button-less design',
  temp_rh_mon: 'Temp & RH monitoring',
  soft_backlight: 'Soft backlight',
  sixteen_ala: '16 alarms',

  snow: 'Qingping Air Monitor',
  // snow2: 'Qingping Air Monitor 2',
  pm25: 'PM2.5',
  pm10: 'PM10',
  co2: 'CO₂',
  tvoc_s: 'tVOC',
  temp: 'Temp',
  temp_m: 'Temp & RH',
  temperature: 'Temperature',
  hum: 'RH',
  // etvoc: 'eTVOC',
  // noise: 'Noise',
  snow_screen: 'HD touchscreen',
  shell: 'Aluminum outer casing',
  snow2_sensor: 'Replaceable PM sensor',

  dany: 'Qingping Air Monitor Lite',
  touchbar: 'Touch bar',
  support_all: 'Works with Apple Home and{br} Mi Home',
  support_all_pc: 'Works with Apple Home and Mi Home',

  sparrow: 'Qingping Bluetooth Gateway',
  connect_mi: 'Connects Mi Bluetooth devices to the Internet',
  connect_mi_en_1: 'Connects Mi Bluetooth devices to the Internet',
  enable_remote: 'Enables remote access',
  automation: 'Automation',

  grasp_guitar_sip: 'Grasp guitar with built-in magnets',
  e_link: 'E-ink display',
  record_history_data: 'Automatically record historical data',

  pheasant_co2_name: 'Qingping CO2 & Temp & RH Monitor',
  pheasant_co2_index_desc: 'Wireless Connectivity{br} Long Battery Life',
  pheasant_co2_title: 'Qingping CO2 & Temp & RH Monitor',
  pheasant_co2_des1: 'High precision sensors · Long battery life',
  pheasant_co2_des1_1: 'High precision sensors',
  pheasant_co2_des1_2: 'Long battery life',
  pheasant_co2_des2: 'Wi-Fi/NB-loT/LoRa',

  chicken_title: 'Qingping Bluetooth Clock',
  chicken_des1: 'A cute alarm clock · Temp & RH sensor',
  chicken_des1_1: 'A cute alarm clock',
  chicken_des1_2: 'Temp & RH sensor',
  chicken_des2: 'Translucent screen',

  parrot_title: 'Qingping Motion & Light Sensor',
  parrot_des1: 'Sensitive motion detection · Precise light measurement',
  parrot_des1_1: 'Sensitive motion detection',
  parrot_des1_2: 'Precise light measurement',
  parrot_des2: 'Automation',

  device_management: 'Device management',
  data_insights: 'Data insights',
  historical_data: 'Historical data',
  access_remote: 'Remote access',
  air_quality: 'City air quality',
  forecast: 'Weather forecast',

  team_management: 'Team management',
  data_collection: 'Data collection',
  device_sharing: 'Device sharing',
  alarm_notification: 'Realtime alarm notifications',

  customize_device: 'Customize your Qingping devices',
  own_platform: 'Connect Qingping devices to your own platform',
  own_platform_m_1: 'Connect Qingping devices',
  own_platform_m_2: ' to your own platform',

  cooperate: 'Business Contact',
  serve: 'Customer Service',
  company_name: '© Qingping',

  about_us: 'About Us',
  privacy: 'Privacy Policy',
  contact_us: 'Contact Us',

  specifications: 'Specs',
  buy: 'Buy',

  home_text: 'See Temperature and{br} Humidity at a Glance',
  sensor: 'Sensirion sensor',
  wall_model: 'Wall mode',
  thirdth_title: 'Desktop mode',
  temp_rh_t: 'T Version',
  temp_rh_h: 'H Version',
  temp_rh_m: 'M Version',
  temp_rh_t_full: 'Qingping Temp & RH Monitor T Version',
  temp_rh_h_full: 'Qingping Temp & RH Monitor H Version',
  temp_rh_m_full: 'Qingping Temp & RH Monitor M Version',
  comfort_zone: 'Comfort Zone',
  goose_desc:
    'During winter, human body feels more comfortable when the temperature is between 20℃~24℃ and the RH is between 30%~60%; during summer, that range is 23℃~26℃ and 25%~60%.',
  goose_desc_m:
    'During winter, human body feels more comfortable{br} when the temperature is between 20℃~24℃ and the RH is between 30%~60%; during summer, that range is 23℃~26℃ and 25%~60%.',
  thirdth_desc: 'Open the back cover, place it on the table.',
  fourth_title: 'Wall Mode',
  fourth_desc: '3 steps:',
  fourth_desc_1: 'Stick the wall sticker on the wall;',
  fourth_desc_2: 'Attach Qingping Temp & RH Monitor on the sticker;',
  fourth_desc_3: 'Adjust the position.',
  fourth_desc_4:
    'Qingping Temp & RH Monitor has built-in magnets, wall sticker has a built-in{br} iron plate.',
  fifth_title: 'E-ink Display, More Legible',
  sixth_title: 'Ultra-wide Viewing Angle, Almost 180°',
  sixth_desc: 'Easier to see temperature and humidity, even in tricky angles.',
  seventh_title: 'High Accuracy Sensor from Sensirion',
  seventh_title_1: 'High Accuracy Sensor from Sensirion',
  seventh_title_2: ' ',
  seventh_desc: 'Quick response {spot} Accurate {spot} Stable',
  eighth_title: 'One Button Cell Lasts 8 ~ 12 Months',
  eighth_title_m: 'One Button Cell{br} Lasts 8 ~ 12 Months',
  eighth_desc:
    '* This product uses CR2430 battery, it normally lasts 8 ~ 12 months in indoor environments (data provided by Qingping lab). The actual standby time may differ depending on the environment and battery.',
  eighth_desc_1:
    '* This product uses CR2430 battery, it normally{br} lasts 8 ~ 12 months in indoor environments{br} (data provided by Qingping lab).{br} The actual standby time may differ depending on{br} the environment and battery.',
  h_support: 'T Version / H Version Supports Apple Home',
  t_support: 'T Version Supports Apple Home',
  check_anytime:
    'Check home temperature and humidity anytime,{br} anywhere, and set up automation.',
  check_remote:
    '* Remote access and automation need to set up your HomePod or Apple TV as a home hub.',
  check_remote_1:
    '* Remote access and automation need to set up your HomePod or Apple TV as a home hub.',
  check_thread:
    'To use Thread connection, you need to set up a Thread-enabled home hub,{br} such as HomePod (2nd generation), HomePod mini or Apple TV 4K (2nd generation or{br} 3rd generation with an Ethernet port), or a supported third-party border router.',
  check_thread_1:
    'To use Thread connection, you need to set up a Thread-enabled home hub, such as HomePod (2nd generation), HomePod mini or Apple TV 4K (2nd generation or 3rd generation with an Ethernet port), or a supported third-party border router.',
  check_remote_2: 'Need to set up your HomePod or Apple TV as a home hub.',
  check_remote_3: 'Need to set up HomePod or{br} Apple TV as a home hub.',
  siri_support: 'Supports Siri',
  hey_siri: "Hey Siri, what's the home temperature right now?",
  home: 'Home',
  now_humi: 'Living Room humidity 53%.',
  now_temp: 'Living Room temperature 24.8˚.',
  detail: 'More',
  common_around: 'Favorite Accessories',
  meetting_room: 'Living Room',
  short_desc: 'Qingping Temp...',
  two_sensor: '2 Sensors',
  m_support: 'M Version Supports Mi Home',
  with_gateway_1: 'When connected with ',
  with_gateway_2: 'Mi Home Bluetooth gateway devices',
  with_gateway_3: ' (such as ',
  with_gateway_4: 'Qingping Bluetooth Gateway',
  with_gateway_5:
    '), you can access Qingping Temp & RH Monitor remotely, check historical data, or set up automation with other Mi Home devices.',
  humidifier: 'Humidifier',
  air_conditioner: 'Air conditioner',
  mi_home: 'Mi Home',
  qingping_bluetooth_gateway: 'Qingping Bluetooth Gateway',
  electric_heater: 'Electric heater',
  auto_record_thirty: 'Historical Data,{br} Up to 30 Days',
  nineth_title_1: 'Historical Data',
  nineth_title_2: 'Up to 30 Days',
  need_plus: '* Use Qingping+ app to get the historical data.',
  choose_version: 'Choose the Right Version',
  wireless: 'Wireless',
  thread: 'Bluetooth / Thread',
  bluetooth: 'Bluetooth',
  support_operation: 'Supported OS',
  iOS: 'iOS',
  iOS_Android: 'iOS and Android',
  support_app: 'Supported App',
  family: 'Home',
  remote_h: 'Remote Access',
  remote_access: 'Remote Access and Automation',
  need_thread:
    'To use Thread connection, you need to set up a Thread-enabled{br} home hub, such as HomePod (2nd generation), HomePod mini or Apple TV 4K (2nd generation or the 3rd generation with an Ethernet port), or a supported third-party border router.',
  need_homepod_tv: 'Need to set up HomePod or Apple TV as a home hub.',
  need_mi_qingping_gateway:
    'When using Mi Home app, you need to set up a Mi Home Bluetooth gateway device,{br} such as ',
  need_qingping_gateway: 'Need to set up ',
  need_mi_qingping_gateway_m:
    'Mi Home app Need to set up a Mi Home Bluetooth gateway device, such as ',
  need_qingping_gateway_m: ' app Need to set up ',
  break: ',',
  end: '.',

  p_size_long: '74 mm',
  p_size_short: '14 mm',
  exterior: 'Design',
  p_size: 'Size',
  p_size_value: '74 × 74 × 14 mm',
  p_color: 'Color',
  p_color_value: 'White',
  range: 'Measurement Range',
  p_temp: 'Temperature',
  p_temp_value: '0 ~ 50℃ ',
  p_hum: 'Humidity',
  p_hum_value: '0 ~ 99.9%RH',
  under_ninty:
    '* Do not place this product in environment with humidity higher than 90% for long term use.',
  p_specifications: 'Specs',
  other_specs: 'Other Specs',
  other: 'Other ',
  p_name: 'Name',
  p_name_value: 'Qingping Temp & RH Monitor',
  p_model: 'Model',
  p_model_value_t: 'CGG1T',
  p_model_value_h: 'CGG1H',
  p_model_value_m: 'CGG1',
  p_support_platform: 'Platform',
  p_support_app: 'Supported App',
  p_manage_platform: 'Management Platform',
  p_weight: 'Weight',
  p_weight_value: '59 g',
  p_weight_value_t: '54 g',
  p_wireless: 'Wireless',
  p_wireless_value_t: 'Bluetooth / Thread',
  screen_size: 'Screen size',
  screen_size_value: '55 x 55 mm',
  p_wifi: 'Network',
  p_wifi_value: 'Bluetooth 5.0',
  p_battery: 'Battery',
  p_battery_value: 'CR2430 button cell',
  p_pack: 'In the Box',
  p_main: 'Qingping Temp & RH Monitor',
  p_cr: 'CR2430',
  p_battery_1: 'button cell',
  p_instructions: 'User Manual',
  mi_app_need_1:
    'When using Mi Home app, you need to set up Mi Home{br} Bluetooth gateway devices such as ',
  mi_app_need_2: 'When using ',
  mi_app_need_3: ', you need to set up{br} ',

  ph_first_title: 'Qingping Temp & RH Barometer',
  ph_first_text:
    'LoRa/NB-IoT/Wi-Fi Long distance wireless connection {br}Rechargeable lithium battery · Super long standby time',
  ph_first_text1: 'Qingping Temp & RH{br} Barometer Pro S',
  ph_first_text1_m: 'Qingping Temp & RH Barometer Pro S',
  ph_first_text2: 'Qingping Temp & RH{br} Monitor Pro S',
  ph_first_text2_m: 'Qingping Temp & RH Monitor Pro S',
  ph_first_text3:
    'Professional Solution of Temperature and Humidity Monitoring for Multiple Scenarios',
  ph_first_text4: 'Monitor indoor environment, even the extreme ones.',
  ph_first_text5: 'Temperature Range',
  ph_first_text6: 'Air Pressure Range',
  ph_first_text7: 'Battery',
  ph_first_text8: '-30 ~ 55℃',
  ph_first_text9: '-20 ~ 50℃',
  ph_first_text10: '30 ~ 125 kPa',
  ph_first_text11: 'N/A',
  ph_first_text12: '2450mAh',
  ph_first_text13: '2600mAh',
  ph_first_text14: 'View full specifications',
  p_second_title: 'Long Distance Wireless Connection{br} with Multiple Choices',
  p_second_desc: 'Choose the one suits you best.',
  p_second_lora: 'LoRaWAN gateway or base station is required.',
  p_second_nbiot:
    'NB-IoT version includes a 6-year service plan.{br} (50 MB free data per year)',
  p_second_wifi: 'Periodically networking and over-limit reporting.',
  p_third_title: 'Professional Use in Different Scenarios',
  p_third_factory: 'Plant',
  p_third_warehouse: 'Warehouse',
  p_third_market: 'Supermarket',
  p_third_supermarket: 'Mall',
  p_third_hospital: 'Hospital',
  p_third_pharmacy: 'Pharmacy',
  p_third_winecellar: 'Wine cellar',
  p_third_polytunnel: 'Greenhouse',
  p_fourth_title:
    'High-capacity Rechargeable Lithium Battery USB-C Charging Port',
  p_fourth_text1:
    'The battery has a super long standby time, or you can plug it in USB power all the time.',
  p_fourth_text2: 'Battery Capacity',
  p_fourth_text3: 'Charging Port',
  p_fourth_text4: 'Power Input',
  p_fourth_text5: 'USB-C',
  p_fourth_text6: '5V = 1A',
  p_fourth_desc_1: '3350mAh Capacity',
  p_fourth_desc_2: 'USB-C Charging Port',
  p_fourth_desc_3: 'Support USB power suppl',
  p_fifth_title: 'Ultra-low Power Consumption Design, Super Long Standby Time',
  p_fifth_note:
    '* The above data are based on test results in our lab. Ambient temperature, network signal level, updating interval, and uploading volume all affect standby time.',
  p_calculator_title: 'Standby Time Calculator',
  p_calculator_subtitle:
    'See how long the standby time is in different situations.',
  p_network_type: 'Network',
  p_network_signal: 'Signal',
  p_local_interval: 'Recording Interval',
  p_net_interval: 'Uploading Interval',
  p_environment: 'Ambient Temperature',
  p_around: 'Estimated standby time',
  p_about: 'Approximately',
  p_month: 'months',
  p_day: 'days',
  p_tips: '* Estimated standby time may be different from actual use.',
  p_minute: 'minute',
  p_minutes: 'minutes',
  p_hour: 'hour',
  p_hours: 'hours',
  p_good: 'Excellent',
  p_normal: 'Normal',
  p_bad: 'Poor',

  wulian_desc:
    'A platform for device management, data collection, and real-time alerting services of Qingping commercial products.',
  wulian_desc_m:
    'A platform for device management, {br} data collection, and real-time alerting services {br} of Qingping commercial products.',
  p_seventh_title:
    'Remotely Configurable Uploading {br} and Notification Settings',
  p_seventh_img_1: 'Data uploading interval',
  p_seventh_img_2: 'Data recording interval',
  p_seventh_img_3: 'Notification trigger',
  p_eighth_title: 'Detailed APIs and Documents for Customization Development',
  p_eighth_email: 'Qingping Developer Platform: {link}',
  p_nineth_title_1: 'Bosch or ROHM Air Pressure Sensor',
  p_nineth_title_1_1:
    '* Only applicable to Qingping commercial Temperature and Humidity Barometer S.',
  p_nineth_title_1_m: '* For Qingping Temp & RH Barometer Pro S',
  p_nineth_title_2: 'Sensirion Temp & RH Sensor',
  resolution: 'Resolution',
  space: ' ',
  p_nineth_temp: 'Temperature',
  p_nineth_humi: 'Humidity',
  typical_accuracy: 'Accuracy',
  p_tenth_title: 'Easy to Mount on a Wall',
  p_tenth_desc_1: 'Adhesive hook',
  p_tenth_desc_2: 'Hanging hole on the back',
  ph_size_long: '77.2 mm',
  ph_size_height: '27.8 mm',
  ph_size_value: '77.2 × 77.2 × 27.8 mm',
  ph_color_value: 'White',
  ph_pressure: 'Air pressure',
  ph_pressure_value: '30 ~ 125 kPa',
  ph_pressure_value_2: 'N/A',
  ph_temp_value: '-30 ~ 55℃ ',
  ph_temp_value_2: '-20 ~ 50℃ ',
  ph_hum_value: '0 ~ 99.9%RH',
  ph_name_value: 'Qingping Temp & RH Barometer Pro S',
  ph_name_value_2: 'Qingping Temp & RH Monitor Pro S',
  ph_weight_value: '129 g',
  ph_screen_value: '61.21 × 48.72 mm',
  ph_wifi_value: 'Wi-Fi or NB-IoT or LoRa',
  ph_bluetooth_value: 'Bluetooth 5.0',
  ph_bluetooth: 'Buletooth',
  ph_battery_value: 'Lithium battery',
  ph_battery_size_value: '2450mAh',
  ph_battery_size_value_2: '2600mAh',
  ph_usb_value: 'USB-C',
  ph_input_value: '5V⎓1A',
  ph_sticker: 'Adhesive hook',
  ph_product: 'Product',

  coming: 'Coming Soon',
  coming_soon: 'Coming soon',
  mp_package: 'Product Packaging',
  mp_measure: 'Measure Temperature and Humidity',
  mp_guard: 'Guard Your Wooden{br} Instruments',
  mp_desc_1:
    'Sensitive and precise{br} · Fix on guitar with built-in magnets{br} · Smartphone app',
  mp_desc_1_m:
    '· Sensitive and precise{br} · Fix on guitar with built-in magnets{br} · Smartphone app',
  mp_desc_1_m_1: 'Sensitive and precise',
  mp_desc_1_m_2: 'Fix on guitar with built-in magnets',
  mp_desc_1_m_3: 'Smartphone app',
  mp_story: 'Story of the Product',
  mp_story_1:
    'LEE GUITARS is a guitar brand founded by Jonathan Lee. As a senior musician and guitarist, Mr. Lee is well aware of the importance of keeping the environmental temperature and humidity of the acoustic guitar.',
  mp_story_2:
    'LEE GUITARS Thermo-Hygrometer is carefully designed by Johnathon Lee and Qingping team. It can be conveniently fixed at the sound hole of guitars, and is an ideal choice for musicians to monitor the temperature and humidity of guitar preservation environment.',
  mp_why: 'Why Do Wooden Musical Instruments Need Thermometers?',
  mp_reason:
    'It is expensive to repair a guitar or violin with cracked or deformed body. Even if it is repaired, the sound will change.{br} The suitable temperature for preserving wooden musical instruments is 20 ~ 25°C, and the humidity is 40% ~ 55%.{br} Try to keep wooden musical instruments in a suitable temperature and humidity environment,{br} and prevent them from deforming, cracking or ungluing.',
  mp_reason_m:
    'It is expensive to repair a guitar or violin with cracked or deformed body. Even if it is repaired, the sound will change. The suitable temperature for preserving wooden musical instruments is 20 ~ 25°C, and the humidity is 40% ~ 55%. Try to keep wooden musical instruments in a suitable temperature and humidity environment, and prevent them from deforming, cracking or ungluing.',
  mp_convient: 'Fix on Guitar with Built-in Magnets',
  mp_goods_1:
    'Magnets are built in the sensor end and the display end, and the ends are connected with soft rubber made of TPU, so the product can easily clamp the guitar panel after bending, and measures the internal temperature and humidity of the guitar.',
  mp_screen: 'Display end',
  mp_magnet: 'Magnet built-in',
  mp_attract: 'For attracting the sensor end',
  mp_button: 'Button',
  mp_switch: 'Switch °C/°F',
  mp_cp: 'Long press for{br} Bluetooth pairing',
  mp_tpu: 'Soft TPU rubber',
  mp_bend: 'Easy to bend',
  mp_sensor_end: 'Sensor end',
  mp_built_in: 'Magnet built-in',
  mp_cavity: 'Can go deep into{br} the cavity',
  mp_e_link: 'E-ink Display',
  mp_clear: 'Clear and Distinct',
  mp_goods_2:
    'It can provide you a comfortable viewing experience like paper, with a wide viewing angle and legibility, even when the ambient light is very low.',
  mp_superview: 'Wide viewing angle',
  mp_high_contrast: 'High contrast ratio',
  only_bluetooth: 'Bluetooth',
  mp_bluetooth: 'Bluetooth',
  only_wifi: 'Wi-Fi',
  mp_wireless: 'Not Just Wireless',
  mp_ble:
    '· Bluetooth 5.0.{br}· View real-time temperature and humidity, historical data via app.{br}· Get notifications when temperature or humidity over-limit.',
  mp_ble_1: 'Bluetooth 5.0.',
  mp_ble_2: 'View real-time temperature and humidity, historical data via app.',
  mp_ble_3: 'Get notifications when temperature or humidity over-limit.',
  mp_upload:
    '* The time interval of data stored in the product is 15 minutes, and the data will be automatically uploaded {br}when the product is connected to',
  mp_upload_magpie: '"Lee Guitars"',
  mp_product_name: 'Lee Guitars',
  mp_upload_or: 'or',
  mp_upload_plus: '"Qingping+"',
  mp_upload_end: 'app.',
  mp_check:
    '* To access remotely or get notifications, you need{br} to set up a suitable ',
  mp_check_bluetooth: 'Bluetooth gateway device.',
  mp_create_automation:
    '* To receive notifications from Mi Home app, you{br} need to create relevant automation first.',
  mp_love_music: 'For People Who Love Music',
  mp_guardian: ' ',
  mp_suitable:
    "With suitable Bluetooth gateway devices, LEE GUITARS Thermo-Hygrometer can remotely monitor temperature and humidity of your guitar, and timely notify you when over-limit. Be aware of your guitar's status without opening its box, no matter where you are.",
  mp_suitable_m:
    "With suitable Bluetooth gateway devices, LEE GUITARS Thermo-Hygrometer can remotely monitor temperature and humidity of your guitar, and timely notify you when over-limit. Be aware of your guitar's status without opening its box, no matter where you are.",
  mp_purchase:
    '* Bluetooth gateway devices might need to be purchased separately.',
  mp_sensor: 'Sensor from Sensirion',
  mp_sensitive: 'Accurate and Sensitive',
  mp_art:
    "LEE GUITARS Thermo-Hygrometer adopts a Sensirion temperature and humidity sensor, which is widely praised in industrial and consumer markets. With the exquisite structural design and ART algorithm, it can sensitively measure the temperature and humidity inside the guitar's cavity.",
  mp_low_consumption:
    'Ultra-low Power Consumption{br}Up to 8 Months Battery Life',
  mp_desc_2:
    'With ultra-low power consumption sensor and chip, and a CR2032 button cell, the product can be used continuously for 8 months in normal room temperature environment.',
  mp_desc_2_m:
    'With ultra-low power consumption sensor and chip, and a CR2032 button cell, the product can be used continuously for 8 months in normal room temperature environment.',
  mp_desc_3:
    '* The battery life will vary depending on the actual use environment.',
  mp_little_thing: 'A Delicate Little Thing',
  mp_weight:
    'Only 30 g, portable and light, can be fixed on a guitar or placed in its box.',
  mp_more: 'More Details',
  mp_thin: 'Ultra-thin body',
  mp_design: 'Mellow and coherent design',
  mp_beauty: 'Beautiful and functional',
  mp_tpu_2: 'TPU soft material',
  mp_bend_time: 'Can be bent 5000+ times',
  mp_rotary: 'Rotary battery compartment cover',
  mp_change_battery: 'Change batteries easily',
  mp_size_long: '131 mm',
  mp_size_short: '37 mm',
  mp_size_border: '12.4 mm',
  mp_size: '131 × 37 × 12.4 mm',
  mp_color: 'White',
  mp_model: 'CGM1',
  mp_real_weight: '30 g',
  mp_screen_size: 'Diameter 26 mm',
  mp_screen_size_value: '26 × 26 mm',
  mp_temp_range_label: 'Temperature',
  mp_humi_range_label: 'Humidity',
  mp_ble_type: 'Connectivity',

  do_slogan: 'A Fresh Start Every Day',
  do_desc: 'Button-less design · 16 alarms',
  do_snooze: 'Snooze',
  do_ringtones: '8 ringtones',
  do_backlight: 'Adjustable backlight',
  do_simple: 'Button-less, Clean and Simple',
  do_desc_2:
    'Press the product down to lit the backlight, or dismiss an alarm; The operation is simple and interesting, and the soft rubber base with delicate internal design ensures the comfortable pressing feel.',
  do_customize: 'Customize Your Own Alarm Clock',
  do_desc_3:
    'Auto-sync the time when connected to the mobile app; Up to 16 alarms, with snooze and repeat set separately; 8 ringtones, each of them can pleasantly wake you up every morning; Volume adjustment is also available on the app.',
  do_sync_time_1: '* Use ',
  do_sync_time_2: ' or Mi Home app to sync time or change settings.',
  do_shot_off:
    '* Long press the device for 6 seconds to shut off all alarms, and press another 6 seconds to reactivate them.',
    do_des_sparrow:'{sp3}With a {sp1}, you can remotely adjust the settings of Qingping Bluetooth Alarm Clock via {sp2}, and Qingping Bluetooth Alarm Clock will sync the time via the gateway automatically.',
  do_alarm: 'Alarm',
  do_year: 'Year/Month/Day',
  do_week: 'Day of the week',
  do_temp_humi: 'Temperature/Humidity',
  do_time: 'Time',
  do_joy: 'Simple and Fun, {br}Start Your Morning with Joy',
  do_desc_4:
    'When an alarm rings, pat the clock to snooze, it will ring again after 10 minutes. Gently awaken you,{br} and make the getting up less annoying.',
  do_one_more: 'Pat once, snooze (ring again in 10 minutes).',
  do_two_step: 'Pat twice, stop the alarm.',
  do_monitor: 'Monitor Home Temperature & Humidity',
  do_guard: 'Guard Your Comfort',
  do_sensor_care:
    'The sensor from Sensirion sensitively measures the temperature and humidity changes, and makes it easier for you to take care of your family.',
  do_pat: 'Pat to Light Up the Darkness',
  do_press:
    'Press the clock, and the backlight lights up, so you can easily and peacefully check the time, no matter in a lazy morning or late at night.',
  do_adjust: 'Adjust the Backlight According to Your Needs',
  do_desc_5:
    "For example, let the brightness drop to 50%  from 10pm to 7am. The duration of the backlight is also adjustable from 0 to 30 seconds. So that it won't hurt your eyes at night and can be seen clearly during the day.",
  do_available: '* This function is available in ',
  do_available_2: 'or Mi Home app.',
  do_smart: 'A Smart Butler of Comfort',
  do_desc_6:
    'You can set up automation with other Mi Home devices. For example: Turn on the heater automatically when the temperature is below 20˚C.',
  do_desc_7: '* Need to work with ',
  do_desc_7_2: 'Mi Home Bluetooth gateway devices',
  do_desc_7_3: ' (such as ',
  do_desc_7_4: 'Qingping Bluetooth Gateway',
  do_desc_7_5: '), and create automation via Mi Home app.',
  do_concentrated: 'Keep Concentrated, Away from Interferences',
  do_desc_8:
    'Put down your mobile phone, read a book or meditate before going to bed, or work efficiently for 1 hour…',
  do_routine: 'Set Alarms to Fit Your Routine',
  do_desc_9: '* Set alarms to suits your life or work routine in ',
  do_desc_9_2: 'or Mi Home.',
  do_desc_10:
    '* To add alarms or change settings, Qingping Bluetooth Alarm Clock and your mobile phone should be within the Bluetooth transmission range.',
  do_desc_11:'* With a {sp1}, you can remotely adjust the settings of Qingping Bluetooth Alarm Clock via {sp2}.',  
  do_kids: 'A Study Companion for Kids',
  do_habit: 'Cultivate good time management habit.',
  do_silence: 'Does Not Make a Sound',
  do_sleep: 'Complete silence gives you a nice sleep.',
  do_colorful: 'Colorful Choices',
  do_white: 'Beige',
  do_green: 'Green',
  do_blue: 'Blue',
  do_more: 'More Details',
  do_lcd_superview:
    'LCD covered with polarizing film,{br} results in a larger viewing angle and better legibility.',
  do_plastic: 'Matte plastic outer casing,{br}for comfortable touch feeling.',
  do_tpe: 'Anti-skid TPE base,{br}stabilizes the product on surfaces.',
  do_size: '80.3 × 41 × 83 mm',
  do_size_long: '80.3 mm',
  do_size_short: '83 mm',
  do_size_height: '41 mm',
  do_color: 'Beige, green, blue',
  do_temp_range: '-9.9℃ ~ 49.9℃',
  do_model: 'CGD1',
  do_weight: '99 g (battery no included)',
  do_screen_size: '61 × 61 mm',
  do_battery_type: '2 AA batteries',

  sn_serious: 'Take Your Breathing Seriously',
  sn_automation:
    'Comprehensive air quality monitoring · High accuracy sensors · Retina touchscreen · Automation',
  s_thirdth_title: 'Get to the Bottom of the Air',
  s_thirdth_desc_m:
    "There're multiple factors in indoor environments affecting human health and comfort other than temperature and humidity, such as tVOC and CO₂.",
  s_thirdth_desc_1:
    "There're multiple factors in indoor environments affecting human health and comfort",
  s_thirdth_desc_2:
    'other than temperature and humidity, such as tVOC and CO₂.',
  s_fourth_title: 'Tips: ',
  s_fourth_sub_title: 'tVOC and CO₂',
  s_fourth_desc_1:
    'tVOC refers to total volatile organic compounds, containing thousands of compounds. It may contain irritating gas, part of it may have negative effects on human health. In short, we recommend open windows or turn on ventilating devices when tVOC is high.',
  s_fourth_desc_2:
    'CO₂ (carbon dioxide) is a common compound existing in the air. Human breath is the main cause of CO₂ increase in indoor environments. People may feel sleepy when CO₂ concentration is high. Open windows or turn on air ventilating devices can reduce CO₂ efficiently.',
  s_tenth_title: 'Outdoor Air Quality',
  s_tenth_desc_1:
    'Date and time{spot}Weather {spot}Air quality index {spot}UV index',
  s_tenth_desc_1_m:
    '{spot}Date and time{br}{spot}Weather {br}{spot}Air quality index{br}{spot}UV index',
  s_eleventh_title: 'Two-tone Color{br}Aluminium Outer Casing',
  s_eleventh_title_m: 'Two-tone Color, Aluminium Outer Casing',
  s_eleventh_sub_title: 'White{spot}Black',
  s_eleventh_desc: 'Superior texture, matching different decors.',
  s_seventh_title: 'Retina IPS Display',
  s_seventh_desc_1:
    '3.1-inch {spot} 328 ppi {spot} Capacitive touchscreen {spot} Auto-brightness',
  s_seventh_desc_1_m:
    '{spot} 3.1-inch {br}{spot} 328 ppi {br}{spot} Capacitive touchscreen {br}{spot} Auto-brightness',
  s_seventh_desc_2:
    'As exquisite as a high-end smartphone, with a large viewing angle and high contrast.',
  s_nineth_title: 'Just Touch, As Intuitive As a Smartphone',
  s_nineth_desc: 'Tap and slide, easy peasy.',
  s_fifth_title: 'Unparalleled Performance',
  s_fifth_sub_title:
    '{spot} Fast {br}{spot} Accurate {br}{spot} Less drift in long-term use',
  s_fifth_icon_text_1: '1.2GHz 4-core A7 processor',
  s_fifth_icon_text_2: 'Multi-mode compensation algorithm',
  s_sixth_title:
    'Innovative Vent Design, Makes the Sensors Work More Efficiently',
  s_sixth_desc_1: 'Efficient {spot} Accurate {spot} Quiet',
  s_sixth_desc_2:
    'The vent holes have more contact with air, and will not be blocked easily.',
  s_twelfth_title_1: 'Works with Mi Home',
  s_twelfth_title_2: 'Improve Air Quality via Automation',
  s_twelfth_desc:
    'Qingping Air Monitor is an excellent partner device for improving air quality.{br} It works with different kinds of ventilating devices, air purifiers, air conditioners, fans and humidifiers. Once temperature, humidity, PM2.5, tVOC or CO₂ reaches a certain level, the designated device will be turned on or off automatically.',
  sn_fresh_air: 'Air Exchanger',
  sn_pulifier: 'Air Purifier',
  sn_eighth_title: 'Works with Qingping+ App',
  s_eighth_desc:
    'Check out readings and recommendations at any time, anywhere.',
  s_thirteenth_title:
    'Independent Monitor, Judges Air Quality More Objectively',
  s_thirteenth_desc_1:
    "The built-in sensors of air conditioners, air purifiers, and fresh air systems can only detect the air quality in the small range around the device, which is not the room's actual condition.",
  s_thirteenth_desc_2:
    'Put your air monitor in places where you have more concern, it will show you the objective result.',
  s_fourteenth_title: 'Upgradable, Keep Moving Forward',
  s_fourteenth_desc:
    'Like a smartphone, you can upgrade the device OTA to get new features.',
  pm25_range: '0 ~ 999 μg/m³',
  temp_range: '-10 ~ 50℃',
  hum_range: '0 ~ 100%RH',
  tvoc_range: '0.005 ~ 9.999 mg/m³',
  co2_range: '400 ~ 9999 ppm',
  s_size: 'Size',
  s_color: 'White, Black',
  s_size_value_long: '68.4 mm',
  s_size_value_short: '86.3 mm',
  s_size_value_height: '85 mm',
  s_size_value: '85 × 68.4 × 86.3 mm',
  s_weight: 'Weight',
  s_weight_value: '217 g',
  screen_resolution: 'Resolution',
  screen_resolution_value: '720 × 720',
  s_battery_size: 'Capacity',
  s_battery_size_value: '1800mAh/3.7V',
  usb_port: 'Power port',
  usb_port_value: 'USB-C',
  rated_input: 'Power input',
  rated_input_value: '5V⎓1A',
  s_usb_charge: 'USB Cable',

  sp_pure: 'A Bluetooth Gateway for Mi Home and Qingping+',
  sp_automation:
    'Connects Mi Home / Qingping+ BLE accessories to the internet · Enables remote access / automation',
  sp_automation_mobile:
    'Connects Mi Home / Qingping+ BLE accessories to the internet · Enables remote access / automation',
  sp_automation_m1: 'Connects Mi Home / Qingping+ BLE{br}accessories to the internet',
  sp_automation_m2: 'Enables remote access / automation',
  sp_wifi: 'Get Your Door Lock{br} "Connected" to Wi-Fi',
  sp_remote_1:
    'After connected with Qingping Bluetooth Gateway, you can get notifications of your smart lock,{br} check the security events and battery percentage remotely.',
  sp_desc_1:
    '* Qingping Bluetooth Gateway needs to be plugged into a power socket and added into the same Mi Home account with the smart lock,{br} and they should be within the Bluetooth transmission range.',
  sp_check: 'Check Home Temperature and Humidity at Any time, Anywhere',
  sp_desc_2:
    'It supports a variety of devices such as Qingping Temp & RH Monitor M Version, Qingping Temp & RH Monitor Lite and Lee Guitars Bluetooth Thermo-hygrometer;{br} with it, you can remotely check your home\'s temperature and humidity as well as historical data.',
  sp_desc_3:
    '* Qingping Bluetooth Gateway needs to be added into the same Mi Home or Qingping+ account with the temporary and humidity monitor, ',
  sp_desc_3_2: ' and they should be within the Bluetooth transmission range.',
  sp_desc_m:
    '* Qingping Bluetooth Gateway needs to be plugged into a power socket and added into the same Mi Home account with the temporary and humidity monitor, and they should be within the Bluetooth transmission range.',
  sp_smart: 'Set Up Automation Rules,',
  sp_smart_2: 'Make Your Home Smarter',
  sp_smart_m: 'Smart Automation,{br} Start Your Smart Home Safari',
  sp_desc_4: 'Qingping Bluetooth Gateway receives the broadcasting signals of ',
  sp_desc_4_2: 'Mi Home BLE accessories',
  sp_desc_4_3:
    'and transmits them to the server, so you can check out the data or set up automation rules remotely.',
  sp_temp_rh: 'Bluetooth Temp & RH Monitor',
  sp_gateway: 'Qingping Bluetooth Gateway',
  sp_smart_lock: 'Smart Door Lock',
  sp_mihome: 'Mi Home App',
  sp_access: 'Remote Access',
  sp_access_2: 'Automation',
  sp_server: 'Mi Home Server',
  sp_aircondition: 'Air Conditioner',
  sp_humidifier: 'Humidifier',
  sp_heater: 'Heater',
  sp_baby_room: "Make the Baby's Room{br} More Comfortable",
  sp_desc_5:
    'When connect with Qingping Bluetooth Gateway, you can set up automation rules such as "turn on the air conditioner when temperature higher than 28°C". You would take care of your kids, elders and pets more easily.',
  sp_gateway_1: 'Gateway',
  sp_desc_6:
    '* Qingping Bluetooth Gateway needs to be plugged into a power socket and added into the same Mi Home account with the temporary and humidity monitor,',
  sp_desc_6_2: ' and they should be within the Bluetooth transmission range.',
  sp_welcome_home: '"Welcome Home"',
  sp_desc_7:
    'After connected your door lock with Qingping Bluetooth Gateway, you can set up welcoming home automation rules with lights or speakers, such as when the smart door lock is opened, the light would light up automatically for you.',
  sp_desc_8:
    '* Qingping Bluetooth Gateway needs to be plugged into a power socket and{br} added into the same Mi Home account with the door lock, and they should{br} be within the Bluetooth transmission range.',
  sp_work_with: 'Works Great with{br} Mi Home BLE Accessories',
  sp_desc_9: 'It supports all ',
  sp_desc_9_2: 'Mi Home BLE accessorie ',
  sp_desc_9_3: ' Add it to the same Mi Home account with the accessories,',
  sp_desc_9_4:
    'they will be connected automatically as long as they are within the Bluetooth signal transmission range.',
  sp_desc_9_5: ' ',
  sp_desc_9_6: ' ',
  sp_desc_9_m:
    ' Add it to the same Mi Home account with the accessories, they will be connected automatically as long as they are within the Bluetooth signal transmission range.',
  sp_accessories: '* The accessories require additional purchase.',
  sp_accessories_2: ' ',
  sp_accessories_3: ' ',
  sp_small: 'Small, Simple and Pure',
  sp_plugged:
    'It is as small as a power adaptor for smartphones, and can be plugged in anywhere.',
  sp_craftsmanship: 'Delicate Design',
  sp_no_wasted: 'Compact but powerful.',
  sp_size_long: '62.16 mm',
  sp_size_short: '42 mm',
  sp_size_height: '31.5 mm',
  sp_size: '62.16 × 42 × 31.5 mm',
  sp_color: 'White',
  sp_temp_range: '-10 ~ 40℃',
  sp_humi_range: '0 ~ 90%RH',
  sp_weight: '46 g',
  sp_wifi_type: 'IEEE 802.11b/g/n 2.4GHz',
  sp_ble: 'BLE 4.2',
  sp_power: '100–240V~, 50/60Hz, 0.2A',
  sp_env: 'Operation Environment',
  sp_temp: 'Temperature',
  sp_humi: 'Humidity',
  sp_power_in: 'Power in',

  plus_prd: 'The Butler of Qingping Household Products',
  plus_life: 'Life is better.',
  plus_app: 'App Store Download',
  plus_android: 'Android Download',
  plus_plus: 'Qingping+',
  plus_home: 'Assistant of Qingping Home Products',
  plus_device: ' · Device management',
  plus_insights: ' · Reading insights',
  plus_historical: ' · Historical data',
  plus_sharing: ' · Device sharing',
  plus_remote: ' · Remote access',
  plus_notification: ' · Notifications',
  plus_support: '* Supports Android and iOS.',
  download_apk: 'Download APK',

  language: 'language: ',
  language_value_1: '简体中文',
  language_value_2: '繁體中文',
  language_value_3: 'English',

  mall_value_temporary: 'Tmall (China)',
  mall_value_1: 'Amazon US',
  mall_value_2: 'Amazon EU',
  mall_value_3: 'Tmall (China)',
  mall_value_1_T: 'T Version - Amazon US',
  mall_value_2_T: 'T Version - Amazon EU',
  mall_value_3_T: 'T Version - Tmall (China)',
  mall_value_1_H: 'H Version - Amazon US',
  mall_value_2_H: 'H Version - Amazon EU',
  mall_value_3_H: 'H Version - Tmall (China)',
  mall_value_1_M: 'M Version - Amazon US',
  mall_value_2_M: 'M Version - Amazon EU',
  mall_value_3_M: 'M Version - Tmall (China)',

  bran: 'Xiaomi Mijia Air Detector',
  duck: 'Xiaomi Mi Home Bluetooth Thermohygrometer',
  lora: 'Qingping Thermohygrometer(without Bluetooth)',
  lipstick: 'FOXIO Lipstick Mobile Power Supply',
  overview: 'Overview',
  icp: '粤ICP備19156805號',
  co2_cn: 'CO₂',
  tvoc: 'TVOC',
  bran_screen: 'HD Touch Screen',
  duck_sensor: 'High Sensitivity Sensors',
  duck_screen: 'Ultra-low power LCD',
  duck_wall: 'Magnetic Wall Decals',
  low: 'Ultra-low powe',
  thirdth_text_1: 'Bracket not open',
  thirdth_text_2: 'The brackets are open.',
  fourth_text_1: 'Wall sticker',
  fifth_desc_1: 'Electronic Ink Segment Screen',
  fifth_desc_2: 'General low-power band code screen',
  nineth_title:
    'Automatically records temperature and humidity history{br}data，up to 30 days',
  ninth_desc: '* You need to connect to the Apple+ mobile app.',
  cleargass_app: 'Qingping+',
  p_param: 'Specs',
  s_first_desc_1: 'Comprehensive indoor air monitoring',
  s_first_desc_2: 'High precision sensors',
  s_first_desc_3: 'HD touchscreen',
  s_first_desc_4: 'Automation',
  s_fifth_desc:
    'Temperature and humidity、tVOC and CO₂ sensors from Sensirion, Switzerland.',
  s_eighth_sub_title: '15° tilt angle for easier viewing',
  s_tenth_desc_2:
    '* This product is powered by the CR2430 battery and can be used for 8 to 12 months under normal room temperature. {br}Data from Qingping Lab, the actual battery life may vary depending on the environment and battery differences.',
  s_wifi_value: 'IEEE 802.11b/g/n 2.4GHz',
  s_battery_type_value: 'Lithium Ion Battery',
  s_screen_size_value: '3.1 inches',
  seven_battery: 'Battery size 7',
  p_fifth_title_wifi: 'Wi-Fi Version',
  p_fifth_title_nbiot: 'NB-IoT Version',
  p_fifth_title_lora: 'LoRa Version',
  p_fifth_sub_title: 'Standby time',
  p_fifth_sub_desc_1: '(Every 8 hours at 15-minute granularity)',
  p_fifth_sub_desc_2: '(Hourly at 15-minute granularity)',
  p_fifth_temp: '{n}° environment',
  p_fifth_day: '{n} days',
  p_sixth_img_1: 'Remote View Data',
  p_sixth_img_2: 'Receipt notification',
  p_sixth_img_3: 'Exporting Data',
  p_nineth_resolution_value: '0.01 kPa',
  p_nineth_typical_accuracy_value: '±0.05 kPa',
  p_nineth_typical_accuracy_value_desc: '@-20~50℃, 30~125 kPa',
  p_nineth_temp_value_1: '0.1℃',
  p_nineth_temp_value_2: '±0.2℃',
  p_nineth_temp_value_2_desc: '@0~50℃',
  p_nineth_hum_value_1: '0.1%',
  p_nineth_hum_value_2: '±2%',
  p_nineth_hum_value_2_desc: '@10~90%',
  ph_name: 'Name',
  purchase: 'Purchase',
  ph_home_title: 'Networking multi-tasker, standby superhero.',

  download_imd: 'Download',
  download_imd_ios: 'App Store',
  download_imd_android: 'App Store',
  qrcode_text: '微信扫码关注{br}或搜索“青萍科技”',
  care: 'Social Media',
  wechat: 'WeChat',
  sina: 'Weibo',
  facebook: 'Facebook',
  instagram: 'Instagram',

  dany_first_title: 'Qingping Air Monitor Lite',
  dany_first_desc_1: '5 metrics',
  dany_first_desc_2: 'High accuracy sensors',
  dany_first_desc_3: 'Bluetooth gateway',
  dany_first_desc_4: 'Compact design',
  dany_co2: 'CO₂',
  dany_pm25: 'PM2.5',
  dany_pm10: 'PM10',
  dany_temp: 'Temperature',
  dany_humi: 'Humidity',
  dany_second_title:
    'Thousands of Breathing Every Day, Air Quality Matters a Lot',
  dany_second_desc:
    'Clean and fresh air calls for your attention if you want your home to be healthy for you and your family. CO₂ has its reputation to show whether the air is fresh enough, and high level CO₂ would lead to drowsiness. The particles also matter, especially the fine dust particles such as PM2.5, may infiltrate into our cardiovascular system and lead to serious health consequences. ',
  dany_three_title: '5-in-1 Air Monitor',
  dany_three_desc:
    'It tracks 5 major factors of the indoor air quality, including PM2.5, PM10, CO₂,{br} temperature and humidity.',
  dany_three_item1_title: 'PM2.5, PM10',
  dany_three_item1_desc:
    'Monitor particle pollution and{br} turn on the air purifier in time.',
  dany_three_item1_desc_m:
    'Monitor particle pollution and{br} turn on the air purifier in time.',
  dany_three_item2_title: 'CO₂',
  dany_three_item2_desc:
    'Consider the CO₂ is the indicator of whether{br} the air is fresh. When the CO₂ is high, open the{br} window or turn on the air exchanger.',
  dany_three_item2_desc_m:
    'Consider the CO₂ is the indicator of whether{br} the air is fresh. When the CO₂ is high, open the{br} window or turn on the air exchanger.',
  dany_three_item3_title: 'Temperature and Humidity',
  dany_three_item3_desc:
    'Monitor the temperature and the humidity{br} of your room. Turn on the humidifier,{br} air conditioner or other environmental{br} appliances to make the room more comfortable.',
  dany_three_item3_desc_m:
    'Monitor the temperature and the humidity{br} of your room. Turn on the humidifier,{br} air conditioner or other environmental{br} appliances to make the room more comfortable.',
  dany_four_title: 'Read the Air, Take Actions{br} to Have a Healthier Living',
  dany_four_item1_title:
    'Monitor the CO₂ level of your office, ventilate in time to improve your working efficiency.',
  dany_four_item1_desc:
    '* Our breathing plays a major role of adding CO₂ into the indoor air, and when the CO₂ level is high in a small space such as a bedroom or an office, we will feel drowsy.',
  dany_four_item2_title:
    "Monitor the temperature and humidity of the baby's room, pre-set automation rules and the environmental appliances will be triggered to make the room more comfortable automatically, therefore the baby will sleep better.",
  dany_five_title: 'Know the Air from a Distance',
  dany_five_desc:
    'Through the colors of the indicator, you will know the{br} air quality, the temperature and the humidity level of{br} your room, with a glance from a distance.',
  dany_five_example: 'Take CO₂ for instance:',
  dany_five_table_title1: 'Color',
  dany_five_table_title2: 'Range',
  dany_five_table_title3: 'Rate',
  dany_five_table_range1: '400 ~ 1000 ppm',
  dany_five_table_range2: '1000 ~ 2000 ppm',
  dany_five_table_range3: '2000 ~ 3000 ppm',
  dany_five_table_range4: '3000+ ppm',
  dany_five_table_desc1: 'Normal',
  dany_five_table_desc2: 'Slightly High',
  dany_five_table_desc3: 'High',
  dany_five_table_desc4: 'Very High',
  dany_five_memo:
    '* The combination of indicating colors for PM2.5, PM10,{br} CO₂, temperature and humidity are different individually.',
  dany_six_title: 'Tap and Slide, {br}Simple and Easy',
  dany_six_desc:
    'With a touch bar on the top of the air monitor, you may tap or slide to switch the readings as smoothly as to operate a touch screen.',
  dany_seven_title: 'High Accuracy and Fast{br} Responding Sensors',
  dany_seven_desc:
    'PM2.5, PM10, CO₂, temperature and humidity, all 5 measurements are accurate and reliable. ',
  dany_seven_item1_num: '1',
  dany_seven_item1_time: 'Second',
  dany_seven_item1_desc: 'The 5 readings{br} refresh every second.',
  dany_seven_item2_num: '0.3',
  dany_seven_item2_time: 'μm',
  dany_seven_item2_desc: 'The minimum particle{br} detectable is 0.3 µm.',
  dany_eight_title:
    'Air Inlet Grille, Improves the{br} Efficiencies of the Sensors',
  dany_eight_desc:
    'The air inlet grille design at the back would enable more{br} sufficient contact between the sensors and the air.',
  dany_nine_title: 'Works with Apple Home{br} and Mi Home',
  dany_nine_desc:
    'Compatible with many smart devices to make your{br} home a smarter one.',
  dany_ten_title: 'Remote Access, Threshold Alert',
  dany_ten_desc:
    'You may check out the data remotely through Mi Home* or Apple Home* anytime and anywhere.',
  dany_ten_memo:
    'To remotely access the data with Apple Home, you need to set up HomePod or Apple TV as a home hub.',
  dany_eleven_title: 'Also a Bluetooth Gateway{br} for Mi Home',
  dany_eleven_desc: 'It receives the broadcasting signals of ',
  dany_eleven_desc_2: 'Mi Home BLE accessories',
  dany_eleven_desc_3:
    ' and transmits them to the server, so you can check out the data or set up automation rules remotely.',
  dany_twelve_title:
    'Turn On the Air Purifier Automatically{br} When the Air Quality Goes Unpleasant',
  dany_twelve_desc:
    'Qingping Air Monitor Lite is compatible with many smart environmental appliances{br} such as air exchanger, air purifier, air conditioner, fan, humidifier and so on. When{br} the temperature or the humidity or the particle pollution or the CO₂ levels exceed{br} the pre-set target, the appliances would be turned on automatically.',
  dany_twelve_item1: 'When the PM2.5 is over 100, turn on the air purifier*.',
  dany_twelve_item2:
    'When the humidity is lower than 30%, turn on the humidifier*.',
  dany_twelve_memo: 'It requires automation set up in Mi Home app.',
  dany_thirteen: 'Hey Siri',
  dany_thirteen_desc: 'Qingping Air Monitor supports Siri*.',
  dany_thirteen_memo: '* Needs to be added in Home app first.',
  dany_fourteen_title: 'Rechargeable Lithium Battery{br} USB-C Charging Port',
  dany_fourteen_desc:
    'You can plug it in USB power all the time. With a 2000mAh lithium battery{br} built-in, the standby time reaches 7 hours long.',
  dany_fifteen_title: 'Palm Size, Compact Design',
  dany_fifteen_desc: 'It weighs only 143 g, as light as a small apple.',
  dany_sixteen_title: 'Easy to Fit in',
  dany_sixteen_desc:
    'Simple design with white frame and a rounded edge screen,{br} easy to fit in with other items in your room.',
  dany_seventeen_item1:
    'Pixel style UI design shows on a clear OLED screen, enables bigger visible angle and more fun.',
  dany_seventeen_item2: 'Stable on the desk with a soft rubber base.',
  dany_seventeen_item3: 'Dark-green air inlet grille at the back.',

  dany_long: '63.6 mm',
  dany_short: '46 mm',
  dany_height: '54.6 mm',
  dany_size: '63.6 × 46 × 54.6 mm',
  dany_color: 'White',
  dany_temp_range: '0 ~ 40°C',
  dany_humi_range: '0 ~ 95%RH',
  dany_co2_range: '400 ~ 9999 ppm',
  dany_pm25_range: '0 ~ 500 μg/m³',
  dany_pm10_range: '0 ~ 500 μg/m³',
  dany_memo:
    '* Do not place this product in environment with humidity higher than 90% for long term use.',
  dany_name: 'Qingping Air Monitor Lite',
  dany_model: 'CGDN1',
  dany_weight: '143 g',
  dany_screen: '59.9 × 49.9 mm',
  dany_ble: 'Bluetooth 5.0',
  dany_wifi: 'IEEE 802.11b/g/n 2.4GHz',
  dany_battery: '2000mAh',

  duck2: 'Qingping Temp & RH Monitor Lite',
  dk2_first_title: 'Qingping{br} Temp & RH Monitor Lite',
  dk2_first_desc_1: 'High accuracy sensor',
  dk2_first_desc_2: 'Slim bezel',
  dk2_first_desc_3: 'Three placing options',
  dk2_first_desc_4: 'Small and easy to fit in',
  dk2_second_title:
    'Healthier and More Comfortable Living Begins with Monitoring the Temperature and Humidity',
  dk2_second_desc:
    'In the environment of proper temperature and humidity, babies would sleep better. For the health and comfort of our family, Qingping Temp & RH Monitor Lite is a good partner to monitor the temperature and humidity, therefore to take appropriate actions.',
  dk2_three_title: 'Slim Bezel, Easy to Read',
  dk2_three_desc: 'LCD screen, simple and clear.',
  dk2_four_title: 'High Accuracy and Fast Responding{br}Sensor from Sensirion',
  dk2_four_desc:
    'Qingping Temp & RH Monitor Lite is fast responding to the changes and provides accurate readings all the time. Those are accounted for include the global leading Sensirion sensors, exquisite structure design and ART algorithm. ',
  dk2_five_title: 'Bluetooth 5.0, Works with Mi Home',
  dk2_five_desc:
    'Working together with Mi Home Bluetooth gateway devices, Mi Home compatible devices can be triggered by Qingping Temp & RH Monitor Lite to make the room more comfortable automatically',
  dk2_five_left_title:
    'When the room temperature is higher than the pre-set, turn on the air conditioner automatically',
  dk2_five_left_item1: 'Qingping Temp & RH Monitor Lite',
  dk2_five_left_item2: 'Qingping Bluetooth gateway',
  dk2_five_left_item3: 'Air conditioner',
  dk2_five_right_title:
    'When the humidity is lower than the pre-set, turn on the humidifier automatically',
  dk2_five_right_item4: 'Humidifier',
  dk2_six_title:
    'Ultra-low Power Consumption, One Button Cell Lasts for 8 Months',
  dk2_six_desc:
    'With Ultra-low power consumption LCD, one button cell can last for 8 months in common indoor environment for Qingping Temp & RH Monitor Lite.',
  dk2_six_memo:
    '* The actual standby time may differ depending on the environment and battery.',
  dk2_seven_title: '3 Placing Options',
  dk2_seven_title_m: '3 Placing Options',
  dk2_seven_item1_title: 'Wall mode',
  dk2_seven_item1_desc:
    'With the wall sticker inside the package, Qingping Temp & RH Monitor Lite can be placed on the wall.',
  dk2_seven_item2_title: 'Desktop mode',
  dk2_seven_item2_desc:
    'With the stand inside the package, Qingping Temp & RH Monitor Lite can be placed on the desk.',
  dk2_seven_item3_title: 'Magnetic mode',
  dk2_seven_item3_desc:
    'With the magnets built-in, Qingping Temp & RH Monitor Lite can be placed on the objects made of iron. You may have it as a fridge magnet.',
  dk2_eight_title: 'Historical Data Recording, Up to 30 Days',
  dk2_eight_desc:
    'The temperature and humidity data can be recorded every 15 mins in the Qingping Temp & RH Monitor Lite, and the historical data can be saved up to 30 days.',
  dk2_nine_title: 'Small Size, Easy to Fit In',
  dk2_nine_desc: 'As small as 60.5 × 13.7 mm.',
  dk2_ten_desc1:
    'During winter, people feel more comfortable when the temperature is between 20°C ~ 24°C and the RH is between 30% ~ 60%. During summer, those ranges are 23°C ~ 26°C and 25% ~ 60%.',
  dk2_ten_desc2:
    'One button cell is included in the package. Replacement requires additional purchase and can be found in the supermarket or online shop.',
  dk2_ten_desc3:
    'The data can be exported as CSV format through Qingping+ app.',
  dk2_ten_list2:
    ', air conditioner and the humidifier mentioned above require additional purchase. automation needs to be pre-set by users.',
  dk2_ten_list3: 'Air conditioners compatible with Mi Home.',
  dk2_ten_list4: 'Humidifiers compatible with Mi Home.',

  dk2_long: '60.5 mm',
  dk2_height: '13.7 mm',
  dk2_size: '60.5 × 60.5 × 13.7 mm',
  dk2_color: 'White',
  dk2_temp_range: '-9.9 ~ 50°C',
  dk2_humi_range: '0 ~ 99.9%RH',
  dk2_memo:
    '* Do not place this product in environment with humidity higher than 90% for long term use.',
  dk2_name: 'Qingping Temp & RH Monitor Lite',
  dk2_model: 'CGDK2',
  dk2_weight: '38 g',
  dk2_screen: 'Diameter 49.9 mm',
  dk2_ble: 'Bluetooth 5.0',
  dk2_battery: 'CR2430 button cell',
  dk2_pack_wall: 'Wall stickers',
  dk2_pack_desk: 'Desktop stand',
  dk2_pack_battery: 'CR2430 button cell',

  hodor: 'Qingping Door/Window Contact Sensor',
  hodor_first_title: 'Qingping Door/Window{br} Contact Sensor',
  hodor_first_desc_1: 'A guardian of your door/window',
  hodor_first_desc_2: 'Automation',
  hodor_first_desc_3: 'Bluetooth 5.0',
  hodor_second_title: 'Open or Closed?{br} Monitor Your Doors and Windows',
  hodor_second_desc:
    'The sensor would know whether your door or window is open, by sensing the distance change between the two parts of the sensor. It works with most types of doors and windows, and also helps if you want to monitor your drawers or closet and so on.',
  hodor_second_magnet: 'Magnet',
  hodor_second_main: 'Main body',
  hodor_three_title:
    'Unexpected Entry?{br} Instant Push Notification{br} to Alert You',
  hodor_three_desc:
    'The sensor helps to improve the security of your house and your privacy. You can set up rules such as sending you a push notification when an unexpected entry is detected.',
  hodor_four_title_1: 'Works with Mi',
  hodor_four_title_2: ' Home',
  hodor_four_desc:
    'Works with the smart devices compatible with Mi Home to make your home smarter.',
  hodor_five_left_title:
    'When opening a window is detected,  the air purifier will be turned off  automatically to reduce the vain consumption of the filters.',
  hodor_five_item1: 'Qingping Door/Window Contact Sensor',
  hodor_five_item2: 'Qingping Bluetooth Gateway',
  hodor_five_item3: 'Air Purifier',
  hodor_five_right_title:
    'When opening a door is detected, the light will be turned on automatically at night.',
  hodor_five_item4: 'Smart Lighting Device',
  hodor_six_title: 'Bluetooth 5.0',
  hodor_six_desc:
    'By using BLE technology, its standby time can reach 8 months',
  hodor_seven_title: 'Compact Design',
  hodor_seven_desc:
    'Small size and elegant design. The sensor can be placed easily with stickers, requires no tools.',
  hodor_seven_item1: 'Closet',
  hodor_seven_item2: 'Drawer',
  hodor_seven_item3: 'Window/Door',
  hodor_eight_title: 'Installation Instruction',
  hodor_eight_desc:
    '* The distance between the two parts needs to be less than 15mm. Please align the central line or the top line of the two parts.',
  hodor_ten_desc1: 'A Bluetooth gateway device working with Mi Home, such as ',
  hodor_ten_desc1_2:
    ' is needed to enable the automation mentioned on this page',
  hodor_ten_desc2:
    ', air purifier, smart lighting device and other Mi Home compatible devices mentioned on this page require additional purchase.',
  hodor_ten_desc3:
    'Automation rules and effective time need to be pre-set in Mi Home app.',
  hodor_ten_list1: 'Automation rules need to be pre-set in Mi Home app.',
  hodor_ten_list2: 'Mi Home compatible devices.',
  hodor_ten_list3: 'Mi Home compatible devices.',
  hodor_ten_list4:
    'The standby time of 8 months is the test result under the condition of opening or closing the door once an hour on average. The standby time varies according to the usage frequency.',

  hodor_front_bottom: '21.5 mm',
  hodor_front_right: '44 mm',
  hodor_side_bottom: '13 mm',
  magnet_front_bottom: '14 mm',
  magnet_front_right: '29 mm',
  magnet_side_bottom: '7 mm',
  hodor_height: '13.7 mm',
  hodor_size: 'Main body: 44 × 21.5 × 13 mm{br}Magnet: 29 × 14 × 7 mm',
  hodor_color: 'White',
  hodor_name: 'Qingping Door/Window{br} Contact Sensor',
  hodor_model: 'CGH1',
  hodor_weight: 'Main body: 6 g (battery not included){br}Magnet: 7 g',
  hodor_ble: 'Bluetooth 5.0',
  hodor_battery: 'CR1632 button cell',
  hodor_magnet: 'Magnet',

  sensor_name: 'Sensor',

  parrot: 'Qingping Motion & Light Sensor',
  parrot_first_title: 'Qingping Motion & Light Sensor',
  parrot_first_desc_1: 'Sensitive motion detection',
  parrot_first_desc_2: 'Precise light measurement',
  parrot_first_desc_3: 'Automation',
  parrot_second_title: 'Sensitively Detects People Passing By',
  parrot_second_desc: 'With a built-in PIR',
  parrot_second_desc_1:
    ', the product accurately detects human movement. The placement angle is flexible and adjustable, which makes the recognition range wider. You can set up automation triggered by "detects motion" or "stops detecting motion" accordingly.',
  parrot_three_title:
    'Measures Light Exquisitely, for Setting Up Automation Flexibly',
  parrot_three_title_1: ', Not "Bright" or "Dark"',
  parrot_three_desc:
    "You can set up automation according to specific illuminance values, to adapt to various situations. It's your call to turn on the lights automatically when it's just getting dark or in pitch-darkness.",
  parrot_three_range: 'Measurement range of illuminance: ',
  parrot_three_num: '0 ~ 83,000',
  parrot_three_sensor: 'Sensitivity:',
  parrot_three_value: '2',
  parrot_three_unit: 'lux',
  parrot_three_memo: 'Two Sensors Combined for Various{br} Automation',
  parrot_four_title: 'Light the Room up Automatically When Getting Dark',
  parrot_four_desc:
    'When focusing on reading, we usually ignore the room getting dark. You can set up an automation rule such as when the illuminance is lower than 400 lux, turn on the light. It helps to prevent eyestrain and the reading experience would be better.',
  parrot_five_title: 'Turn on a Gentle Light When Waking Up at Night',
  parrot_five_desc:
    'When waking up at night, it could help to turn on a subdued night light. You can set up an automation rule such as when the illuminance is lower than 20 lux and a motion is detected, turn on the light on the bedside table or in the bathroom.',
  parrot_six_title: 'Save Energy When Leaving Home',
  parrot_six_desc:
    "You can set up an automation rule such as when the sensor stops detecting motion, turn off the lights, the air conditioner or other electrical appliances. It'll improve safety and save energy expense.",
  parrot_seven_title: 'Unexpected Entry Alert',
  parrot_seven_desc:
    'You will receive a push notification of a potential threat to your home when it detects an unexpected entry. It helps to improve the home security as a vital component.',
  parrot_check_remote: 'Remote Access and Automation',
  parrot_eight_title:
    'Check the Real-time Illuminance and Historical Data Anywhere Anytime{br} Set Up Automation with Mi Home Devices',
  parrot_eight_desc:
    'Remote access and automation need to set up a Mi Home Bluetooth gateway device, such as ',
  parrot_nineteen_desc: 'Historical Data, Up to 30 Days.',
  parrot_nineteen_memo:
    'To access the historical data with Qingping+ app, T Version needs a home hub, such as a HomePod or Apple TV. M Version needs a ',
  parrot_nine_title: 'Flexible Facing Angle',
  parrot_nine_desc:
    "You can easily adjust the product's facing angle by rotating it on the wall-mounted magnetic base.",
  parrot_ten_title: '3 Placing Options',
  parrot_ten_item1: 'On the wall or even the ceiling',
  parrot_ten_item2: 'On the desk',
  parrot_ten_item3: 'Attached on the iron surfaces',
  parrot_eleven_title: 'Multiple-section Fresnel Lens Enables High Sensitivity',
  parrot_eleven_desc:
    'With a multiple-section Fresnel lens built-in, the sensor is compact yet sensitive.',
  parrot_twelve_title: 'TI Light Sensor, Anti-UV Outer Casing',
  parrot_twelve_desc:
    'A high precision light sensor from Texas Instruments, coupled with a carefully designed lens, housed in an anti-UV out casing which prevents color fading.',
  parrot_thirteen_title: 'Long Standby Time, Up to 2 Years',
  parrot_fourteen_title: 'Simplicity in Design, Easy to Fit In',
  parrot_fifteen_title: 'Easy to Set up',
  parrot_fifteen_item1: 'Method 1:',
  parrot_fifteen_item1_desc:
    'Remove the tape cover from the base, stick the base to your selected position, and then attach the main body.',
  parrot_fifteen_item2: 'Method 2:',
  parrot_fifteen_item2_desc:
    'Tear off the film from one side of the double-sided adhesive contained in the package, stick the double-sided adhesive on the back of the main body, and tear off the film from the other side, then stick the main body at the selected position.',
  parrot_fifteen_main: 'Main body',
  parrot_fifteen_base: 'Base',
  parrot_fifteen_tape: 'Double-sided{br} Tape',
  parrot_sixteen_title: 'Motion Detection Zone',
  parrot_ten_list1:
    'To set up automation, Qingping Motion & Light Sensor T Version needs a home hub, such as HomePod or Apple TV, and set up with Apple Home app; M Version needs a Mi Home Bluetooth gateway device, such as ',
  parrot_ten_list1_1: ', and set up with Mi Home app.',
  parrot_ten_list2:
    'Under the condition of 120 times of motion detection per day on average, in the common room temperature environment.',
  parrot_ten_list3:
    'The diagram below demonstrates the detection zone of the sensor. The data is collected in the room temperature of 25℃, and under the conditions of the sensor affixed on the height of 2.2 meters and the downward inclination of 20°.',

  parrot_bottom: '38 mm',
  parrot_height: '35.9 mm',
  parrot_bottom_bottom: '36 mm',
  parrot_bottom_height: '13.7 mm',
  parrot_size: 'Sensor: 38 × 38 × 35.9 mm, {br}base: 36 × 36 × 13.7 mm',
  parrot_size_sensor: 'Sensor: 38 × 38 × 35.9 mm',
  parrot_size_base: 'Base: 36 × 36 × 13.7 mm',
  parrot_color: 'White',
  detect_distance: 'Motion Detection Distance',
  parrot_distance: '7 m',
  detect_angle: 'Motion Detection Angle',
  parrot_angle: '15° (7 m) ~ 120° (within 2 m)',
  light_range: 'Illuminance',
  parrot_range: '0 ~ 83,000 lux',
  parrot_name: 'Qingping Motion & Light Sensor',
  parrot_t_name: 'Qingping Motion & Light Sensor T Version',
  parrot_m_name: 'Qingping Motion & Light Sensor M Version',
  parrot_t_model: 'CGPR1T',
  parrot_m_model: 'CGPR1M',
  parrot_model: 'CGPR1',
  parrot_weight: 'Sensor: 33.8 g,{br} base: 6.6 g',
  parrot_ble: 'Bluetooth 5.0',
  parrot_work_temp: 'Working Temperature',
  parrot_temp: '-10 ~ 45°C',
  parrot_work_humi: 'Working Humidity',
  parrot_humi: '0 ~ 90%RH',
  parrot_battery: '2 CR2450 Button Cells',
  parrot_pack_body: 'Sensor',
  parrot_pack_bottom: 'Base',
  parrot_pack_stick: 'Double-sided Tape',

  //chicken
  chicken: 'Qingping Bluetooth Clock',
  chicken_first_title: 'More Focused, More Productive',
  chicken_first_desc1: 'Interval timer',
  chicken_first_desc2: 'Alarm clock',
  chicken_first_desc3: 'Count up timer',
  chicken_first_desc4: 'Thermo-hygrometer',
  chicken_second_title:
    'A Clock with Interval Timer, Alarm{br} Clock and Count Up Timer,{br} Better Time Management',
  chicken_second_desc: '6-in-1, a great multifunctional gadget.',
  chicken_second_fun1: 'Alarm Clock',
  chicken_second_fun2: 'Interval Timer',
  chicken_second_fun3: 'Count Up Timer',
  chicken_second_fun4: 'Clock',
  chicken_second_fun5: 'Thermometer',
  chicken_second_fun6: 'Hygrometer',
  chicken_third_title: '开启循环计时',
  chicken_third_title1: '，保持专注',
  chicken_third_title_en: 'Boost Your Productivity with the Interval Timer',
  chicken_third_desc: '工作时段',
  chicken_third_desc1: '保持专注，休息时段',
  chicken_third_desc2: '尽情放松，张弛有度更高效。',
  chicken_third_desc_en:
    'Immerse yourself during working time, and have fun during breaks',
  chicken_fourth_title: 'Up to 16 Alarms, More Organized',
  chicken_fourth_desc:
    'Up to 16 alarms to assist you with your daily routines, and you can set snooze mode',
  chicken_fourth_desc1: 'and repeat rules individually.',
  chicken_fifth_title: 'Count Up Timer',
  chicken_fifth_title1: ', Better Self-discipline',
  chicken_fifth_desc:
    "It'll accompany you to stay focused while you read,{br} meditate or work out.",
  chicken_sixth_title: 'Accurate Temperature and Humidity Sensor',
  chicken_sixth_title1: '3 Smiley Indicators',
  chicken_sixth_desc:
    'With high accuracy and fast responding sensor from Sensirion built-in, it provides accurate temperature and humidity readings. 3 smiley indicators would tell whether the room is comfortable for you.',
  chicken_sixth_level1: 'Good',
  chicken_sixth_level2: 'Ok',
  chicken_sixth_level3: 'Bad',
  chicken_seventh_title: 'Set the Alarms and the Interval Timer Remotely',
  chicken_seventh_desc:
    'With a Qingping Bluetooth Gateway, you can set the alarms and the{br} Interval Timer through Qingping+ app remotely.',
  chicken_eighth_title: 'Automations',
  chicken_eighth_title1: ' to Make the Room More Comfortable',
  chicken_eighth_desc: 'Mi Home compatible devices',
  chicken_eighth_desc1:
    ', can be triggered by Qingping Bluetooth Clock to make the room more comfortable automatically. For example, when the temperature is higher than the pre-set 26℃, turn on the air conditioner automatically.',
  chicken_ninth_title: 'Easy to Sync, Keep Good Time',
  chicken_ninth_desc:
    "Clocks tend to gain or lose a few minutes over time, hard to stay accurate.{br} Qingping Bluetooth Clock would synchronize the cellphone time when connected. If pairing with a Qingping Bluetooth Gateway within Qingping+ app,{br} it'll automatically synchronize the network time twice a day. ",
  chicken_tenth_title1: 'Ultra-thin Screen, Slightly{br} Translucent',
  chicken_tenth_title2: 'Inclined Angle, Easy to Read',
  chicken_eleventh_title: 'Stylish Design, Perfectly Fit In',
  chicken_twelfth_title: 'More Details',
  chicken_twelfth_desc1: 'Ultra-thin,{br} only 5 mm',
  chicken_twelfth_desc2: 'Integrated button',
  chicken_thirty_title: '2 Finishes ',
  chicken_thirty_desc1: 'Pink',
  chicken_thirty_desc2: 'Beige',
  chicken_fourteen_desc1: 'Set the Interval Timer in Mi Home or ',
  chicken_fourteen_desc1_1: ', double click the button to start.',
  chicken_fourteen_desc2:
    'The default setting of a working duration is 25 minutes, you can set it from 5 to 100 minutes as you like.{br} Break duration default setting is 5 minutes, you can set it from 1 to 100 minutes.',
  chicken_fourteen_desc3:
    "When an alarm rings, click the button to snooze, and it'll ring again 10 minutes later.",
  chicken_fourteen_desc4: 'Double click the button to start the timer.',
  chicken_fourteen_desc5:
    'With a Bluetooth gateway device compatible with Mi Home, you can check the temperature and the humidity remotely in Mi Home app. Pairing with a ',
  chicken_fourteen_desc5_1: ' in ',
  chicken_fourteen_desc5_2:
    ' app, you can also check the historical data. The gateway devices require additional purchase.',
  chicken_fourteen_desc6:
    'A Bluetooth Gateway device compatible with Mi Home is needed to enable the automation, or to check the temperature and the humidity remotely. The Bluetooth gateway device and the air conditioner mentioned above require additional purchase. ',
  chicken_fourteen_desc6_1: ' ',

  chicken_bottom: '90.27 mm',
  chicken_height: '93.27 mm',
  chicken_width: '40 mm',
  chicken_size: '90.27 × 93.27 × 40 mm',
  chicken_color: 'Pink, Beige',
  chicken_name: 'Qingping Bluetooth Clock',
  chicken_model: 'CGC1',
  chicken_weight: '53.1 g (button cell not included)',
  chicken_ble: 'Bluetooth 5.0',
  work_temp: 'Temperature',
  chicken_temp: '-9°C ~ 50°C',
  work_humi: 'Humidity',
  chicken_humi: '0 ~ 99%RH',
  chicken_battery: 'CR2430 button cell',

  frog: 'Qingping Temp & RH {br}Monitor Pro E',
  frog_index_desc: 'IP55 Protection{br}Long Standby Time',
  frog_needle: 'Qingping AUX Temp Probe',
  frog_first_title:
    'Water Splash and Dust Resistance, Professional Temperature and Humidity Monitoring Solution',
  frog_first_temp_range: 'Temperature Measurement Range',
  frog_first_humi_range: 'Humidity Mesaurement Range',
  frog_first_level: 'Ingress protection',
  frog_first_level_title: 'Ingress Protection',
  frog_first_special: 'Special Materials',
  frog_first_range1: '-30 ~ 60°C',
  frog_first_range2: '-40 ~ 125°C',
  frog_first_range3: '0 ~ 100%RH',
  frog_first_range4: '-',
  frog_first_range5: 'IP55',
  frog_first_range6: 'IP68',
  frog_first_range7: '-',
  frog_first_range8: 'Food Grade Materials',
  frog_first_complete: 'View full specifications',
  frog_second_title_1: 'IP55',
  frog_second_title_2: ' Enclosure, Water Splash and Dust Resistance',
  frog_third_title:
    'Temperature Probe Extension, Enables Wider Measurement Range',
  frog_third_desc:
    'The temperature probe extension is with IP68 grading protection and food grading materials. It can stay soaked under water to get the temperature, or you can track the data out of somewhere the network signal is blocked.',
  frog_third_left_1: 'IP68',
  frog_third_left_2: ' ',
  frog_third_right: 'Food Grade Materials',
  frog_fourth_title: 'Two Power Options',
  frog_fourth_left: 'Powered by AA batteries',
  frog_fourth_right: 'Powered through the Micro-USB port',
  frog_fourth_right_desc:
    'The product can be powered by USB all the time, but it does not support battery charging.',
  frog_fifth_title: 'Standby Time Calculator',
  frog_fifth_desc: 'How long is the standby time, it is more clear',
  frog_sixth_title: 'Long Distance Wireless Connection with Multiple Choices',
  frog_sixth_desc: 'Choose the one suits you best.',
  frog_sixth_wifi: 'Wi-Fi network is required.',
  frog_sixth_lora: 'LoRaWAN gateway or base station is required',
  frog_sixth_nb: 'CMCC NB-IoT',
  frog_sixth_nb_2: ' service is required',
  frog_seventh_title: 'Enhanced Signal Receiving with the External Antenna',
  frog_seventh_signal: 'Enhanced Signal',
  frog_seventh_cover: 'Wide Range',
  frog_seventh_stable: 'Stable Transmission',
  frog_eight_title: 'High-accuracy Sensor',
  frog_eight_left_1: 'Typical Accuracy',
  frog_eight_left_2: 'Precision',
  frog_eight_left_3: 'Measurement Range',
  frog_eight_left_4: '±0.5°C{br}(@0 ~ 50°C)',
  frog_eight_left_5: '±5%RH{br}(@10% ~ 90%RH)',
  frog_eight_left_6: '0.1°C',
  frog_eight_left_7: '1%RH',
  frog_eight_left_8: '-30 ~ 60°C',
  frog_eight_left_9: '0 ~ 100%RH',
  frog_eight_right_1: '-40 ~ 125°C',
  frog_ninth_title: 'Qingping IoT',
  frog_ninth_desc:
    'A platform for device management, data collection, and real-time alerting services of Qingping commercial products',
  frog_ten_title: 'Detailed APIs and Documents for Customization Development',
  frog_ten_developer: 'Qingping Developer Platform',
  frog_ten_dev_add: 'developer.qingping.co',
  frog_eleven_title: 'Outstanding in Various Scenarios',
  frog_eleven_desc:
    'Workshop, warehouse, server room, cool store, lab, pharmacy, wine cellar, green house, farm and so on.',
  frog_eleven_pharmacy: 'Pharmacy',
  frog_eleven_garbage: 'Warehouse',
  frog_eleven_farm: 'Farm',
  frog_eleven_greenhouse: 'Greenhouse',
  frog_twelfth_title: 'Two Mounting Options',
  frog_twelfth_left_1: 'DIN rail compatible',
  frog_twelfth_left_2: ' ',
  frog_twelfth_right: 'Magnets built-in',
  frog_bottom_1:
    'Qingping Temp & RH Monitor Pro E was tested under controlled laboratory conditions with a rating of IP55 under IEC standard 60529 (spraying the enclosure from all practicable directions at a distance of 2.5~3m, with a stream of water from a standard test nozzle with an internal diameter of 6.3mm, at the water delivery rate of 12.5L/min, over 3 minutes). Qingping Temp & RH Monitor Pro E cannot be soaked in water or placed in prolonged exposure to dust. Splash, and dust resistance are not permanent conditions and resistance might decrease as a result of normal wear. Do not attempt to charge a wet product.',
  frog_bottom_2:
    'Qingping AUX Temp Probe is dust resistance and can be soaked in water. It was tested under controlled laboratory conditions with a rating of IP68 under IEC standard 60529 (maximum depth of 1.5meters for 60 minutes). Splash, water and dust resistance are not permanent conditions and resistance might decrease as a result of normal wear.',
  frog_bottom_3:
    'The materials including stainless steel probe and silicone rubber cable of Qingping AUX Temp Probe were tested under controlled laboratory conditions, and proven as food grade.',
  frog_bottom_4:
    'NB-IoT version includes an 8-year service plan of 50 MB free data per year. It May vary in different batches.',
  frog_bottom_5:
    'Qingping Temp & RH Monitor Pro E is compatible with 35 mm DIN rail, the DIN rail and its accessories require additional purchase.',
  frog_spec_long: '87.6 mm',
  frog_spec_short: '33 mm',
  frog_spec_height: '156.8 mm',
  frog_spec_size: '87.6 × 156.8 × 33 mm(external antenna included)',
  frog_color: 'Dark gray',
  frog_temperature:
    '-30 ~ 60°C (powered by lithium-iron AA batteries){br}-10 ~ 50°C (powered by regular AA batteries){br}0 ~ 40°C (powered by USB)',
  frog_humidity:
    '0 ~ 100%RH (powered by lithium-iron AA batteries){br}0 ~ 90%RH (powered by USB)',
  frog_weight: '118 g',
  frog_screen_size: '64.6 × 44.6 mm',
  frog_wifi: 'Wi-Fi or NB-IoT or LoRa',
  frog_ble: 'Bluetooth 5.0',
  frog_power: 'Micro-USB',
  frog_power_in: '電源接口',
  frog_inout: '5V⎓1A',
  frog_battery_support: 'Batteries',
  frog_battery: '2 lithium-iron or regular AA batteries',
  frog_protect: 'IP55',
  frog_package_battery: '2 lithium-iron AA batteries',
  needle_size:
    'Probe: 6 × 50 mm{br}Cable: 3.5 × 1500 mm{br}Cable holder: 35 × 20 mm',
  needle_temperature: '-40 ~ 125℃',
  needle_weight: '29 g',
  needle_connect_way: 'Connectivity',
  needle_connect: '3.5 mm AUX',
  needle_protect: 'IP68',
  needle_material_left: 'Materials',
  needle_material: 'Food grade 316L stainless steel',
  sensor_detect: 'Cable',
  sensor_body: 'Cable holder',
  needle_body: 'Food grade silicone rubber',
  needle_paste: 'Silicone rubber',
  needle_with: 'PET',
  needle_package_bottom: 'Cable holder',
  needle_package_with: 'Tape{br} (to mount the cable)',
  battery_type: 'Battery',
  battery_type_1: 'Lithium iron AA batteries',
  battery_type_2: 'Regular AA batteries',

  //关于我们
  about_title: 'About Us',
  about_one:
    'Established in 2015, Qingping Technology (Beijing) Co., Ltd. is one of the world\'s leading providers of temperature, humidity, and air quality monitoring products and services.',
  about_two:
    'Qingping is committed to researching, developing, and designing environmental IoT products and providing monitoring and control solutions for consumers and industry clients.',
  about_three:
    'Our products are sold in many countries and regions and have won multiple international design awards, such as Red Dot and iF.',
  about_four:
    'In the consumer market, Qingping products have industry-leading sales volume and favorable rates on mainstream e-commerce platforms, and we have established cooperation with many well-known brands in sensor products.',
  about_five:
    'In the industry market, Qingping has rich customer resources and technology accumulation in sensor application fields such as construction, food, heating, breeding and planting.',
  about_six:
    'The company is headquartered in Beijing, with Shenzhen manufacturing center and Suzhou customer service center; our factory has obtained the ISO9001 certification.',

  //Dove app download
  clock_text_1: 'Qingping Bluetooth Alarm Clock supports the  apps below: ',
  clock_text_2:
    'You can set up alarms, change ringtones, adjust the backlight and the display language of Qingping Bluetooth Alarm Clock with the apps. After each successful connection, it will automatically sync the time.',
  mijia: 'Mi Home (Xiaomi Home)',
  mijia_desc: 'The smart-home app of Xiaomi.',
  app_plus: 'Qingping+',
  app_plus_desc: 'The smart-home app of Qingping.',
  clock_download_tip: 'Or search the app in App Store or Google Play. ',

  //pheasant-co2
  ph_co2_realtime: 'Real-time',
  ph_co2_size_long: '77 mm',
  ph_co2_size_height: '28 mm',
  ph_co2_size_value: '77 × 77 × 28 mm',
  ph_co2: 'CO₂',
  ph_co2_value: '400 ~ 9999 ppm',
  ph_co2_temp_value: '-20 ~ 50°C',
  ph_co2_hum_value: '0 ~ 99%RH',

  ph_co2_name_value: 'Qingping CO₂ & Temp & RH Monitor',
  ph_co2_screen_value: '61 × 49 mm',
  ph_co2_wifi_value: 'Wi-Fi or NB-IoT or LoRa',
  ph_co2_battery_value: '2600mAh',

  ph_co2_first_title: 'Qingping CO₂ & Temp &{br} RH Monitor',
  ph_co2_first_desc:
    'High-accuracy Sensors · Wireless Connectivity · Remote Monitoring{br} Immediate Over-limit Notifications ',
  ph_co2_second_title: 'CO₂ Is an Important Indicator for Indoor Air Quality',
  ph_co2_second_desc:
    'CO₂ (carbon dioxide) is a common compound existing in the air. Human breath usually is the main source of CO₂ increase in indoor environments. When the CO₂ level is high in a small space such as a classroom or an office, people will feel drowsy.{br}',
  ph_co2_second_desc_2:
    'A higher CO₂ level usually indicates that the space is in poor ventilation, which might lead to the accumulation of other pollutants such as VOCs, and the risk of airborne virus infection also increases.',
  ph_co2_third_title: 'Monitor Indoor CO₂ Levels, Take Actions for Improvement',
  ph_co2_third_desc:
    'It can be widely applied to various scenarios such as homes, schools, office buildings, hospitals, restaurants, shopping malls, factories, hotels, laboratories and so on.',
  ph_co2_third_table_color: 'Color',
  ph_co2_third_table_range: 'Range',
  ph_co2_third_table_rate: 'Rate',
  ph_co2_third_range_normal: '400 ~ 1000 ppm',
  ph_co2_third_rate_normal: 'Normal',
  ph_co2_third_range_high: '1000 ~ 1400 ppm',
  ph_co2_third_rate_high: 'Slightly High',
  ph_co2_third_range_pretty_high: '1400+ ppm',
  ph_co2_third_rate_pretty_high: 'High',
  ph_co2_third_memo:
    '* Through the colors of the indicator, you can judge the CO₂ level at a glance.',
  ph_co2_four_desc1:
    'Monitor the CO₂ levels of the office and the classroom, ventilate in time to improve the efficiency of working or studying.',
  ph_co2_four_desc2:
    'Monitoring CO₂ in shopping malls, restaurants and other public places can evaluate the indoor ventilation and personnel gathering level. Proper ventilation, such as turning on the air-exchange system timely, can reduce the concentration and spread of harmful substances. ',
  ph_co2_five_title: 'Temp & RH Monitoring',
  ph_co2_five_desc: 'Wide measurement ranges for professional usage.',
  ph_co2_six_title: 'High Accuracy Sensors from Sensirion',
  ph_co2_six_table_title: 'CO₂ Sensor	',
  ph_co2_six_table_subtitle: 'NDIR CO₂ Sensing Technology	',
  ph_co2_six_table_title_2: 'Temp & RH Sensor',
  ph_co2_range: 'Range',
  ph_co2_precision: 'Typical{br} Accuracy',
  ph_co2_precision_value: '±15% of m.v.',
  ph_co2_temp_value2: '±0.5℃',
  ph_co2_hum_value2: '±5%RH',
  ph_co2_temp_value2_2: '@0 ~ 50℃',
  ph_co2_hum_value2_2: '@10% ~ 90%RH',
  ph_co2_seven_title:
    'High-capacity Rechargeable Lithium Battery USB-C Charging Port',
  ph_co2_seven_desc: 'You can plug it in USB power all the time.',
  ph_co2_eight_interval: 'CO₂ Detection Interval',
  ph_co2_nine_title: 'Long Distance Wireless Connection with Multiple Choices',
  ph_co2_nine_desc: 'Choose the one suits you best.',
  ph_co2_nine_lora: 'LoRaWAN gateway or base station is required.',
  ph_co2_nine_nb: 'CMCC NB-IoT* service is required.',
  ph_co2_nine_memo:
    '* NB-IoT version includes a 6-year service plan of 50 MB free data per year. It may vary in different batches.',
  ph_co2_eleven_title: 'Configurable Intervals of Data Recording and Uploading',
  ph_co2_eleven_desc: 'Data recording interval',
  ph_co2_eleven_memo:
    'Data uploading interval: minimum of 10 minutes and maximum of 24 hours.{br} Data recording interval: minimum of 1 minute and maximum of 60 minutes.',
  ph_co2_twelve_title: 'Multiple Alert Methods',
  ph_co2_twelve_desc:
    'When CO₂, temperature, or humidity reaches a preset threshold, a device gets offline, or the battery level is too low, an alert notification will be sent. ',
  ph_co2_twelve_icon1: 'Buzzer sound*',
  ph_co2_twelve_icon1_desc:
    '* The built-in buzzer will alarm if CO₂, temperature or humidity is over-limit. The buzzer alarm feature can be turned off/on using the Qingping IoT app or website.',
  ph_co2_twelve_icon2: 'App notification',
  ph_co2_twelve_icon3: 'Phone call',
  ph_co2_twelve_icon4: 'Text message',
  ph_co2_twelve_icon5: 'Email',
  ph_co2_thirteen_title: 'No Data Loss During Network Interruption',
  ph_co2_thirteen_subtitle:
    'No need to worry about network interruption. Up to 2,880 sets of data can be saved locally, and the data would be uploaded when the connection is back on.',
  ph_co2_thirteen_desc:
    '* Data of 30 days can be saved locally while the recording interval is 15 minutes.',
  ph_co2_fourteen_title:
    'Detailed APIs and Documents for Customization Development',
  ph_co2_fifteen_title: 'Easy to Mount on the Wall',

  //pheasant-co2 mobile
  ph_co2_first_desc_m_1: '· High-accuracy Sensors',
  ph_co2_first_desc_m_2: '· Wireless Connectivity',
  ph_co2_first_desc_m_3: '· Remote Monitoring',
  ph_co2_first_desc_m_4: '· Immediate Over-limit Notifications ',
  ph_co2_fourteen_title_m:
    'Detailed APIs and Documents for{br} Customization Development',

  //Gecko
  gecko_title: 'Qingping Wall Socket Thermometer',
  gecko_name: 'Qingping Wall Socket Thermometer',
  gecko_desc: 'Wall-mounted · NB-IoT connectivity · High accuracy Sensor',
  gecko_desc_m1: 'Wall-mounted',
  gecko_desc_m2: 'NB-IoT connectivity',
  gecko_desc_m3: 'High accuracy Sensor',
  gecko_second_title: 'Outstanding in Various Scenarios',
  gecko_second_desc:
    'The product can continually monitor, collect, {br}and report the environment temperature.',
  gecko_second_house: 'Residential',
  gecko_second_mall: 'Mall',
  gecko_second_hotel: 'Hotel',
  gecko_second_hospital: 'Hospital',
  gecko_second_warehouse: 'Warehouse',
  gecko_second_factory: 'Plant',
  gecko_third_title: 'Easy to Install and Reliable',
  gecko_third_desc:
    'The product can replace the existing 86-type{br} wall socket without affecting the daily use of power. Fixed position, less worries about being moved.',
  gecko_fourth_title: 'Mains Powered, No Batteries{br} Required',
  gecko_fourth_desc:
    'The product takes power directly through the wall box, so no worries about battery replacement issues.',
  gecko_fifth_title: 'Dual-sensor Structure Design',
  gecko_fifth_desc:
    'Two high accuracy sensors are built into the product, which can effectively reduce the impact of load-induced internal heat on the accuracy of environment temperature measurement.',

  gecko_table_title: 'Qingping Wall Socket Thermometer',
  gecko_table_a: 'Typical Accuracy',
  gecko_table_b: 'With load: ±0.5℃ {br}Without load: ±0.3℃ {br}(@0 ~ 50°C)',
  gecko_table_c: 'Resolustion',
  gecko_table_d: '0.1℃',
  gecko_table_e: 'Range',
  gecko_table_f: '-20 ~ 50℃ ',
  gecko_sixth_title: 'NB-IoT Wireless Connection',
  gecko_sixth_desc:
    'NB-IoT SIM card from CMCC included{br}, with wide coverage and low power consumption.',
  gecko_seventh_title: 'Remote Monitoring,{br} High-efficiency Management',
  gecko_seventh_desc:
    'The product is compatible with Qingping IoT which is a platform for device management, data collecting, and real-time alerting services.',
  gecko_seventh_app_a: 'Download the ',
  gecko_seventh_app_b: 'Qingping IoT app',
  gecko_seventh_app_c: ' or visit the ',
  gecko_seventh_app_d: 'website',
  gecko_seventh_app_e: '.',
  gecko_eighth_title: 'Multiple Alert Methods',
  gecko_eighth_desc:
    'Temperature and humidity threshold alerts, device offline alerts.',
  gecko_eighth_t1: 'Push Notification',
  gecko_eighth_t2: 'Phone Call',
  gecko_eighth_t3: 'Text Message',
  gecko_eighth_t4: 'Email',
  gecko_nineth_title: 'Configurable Intervals of Data Recording and Uploading',
  gecko_nineth_desc:
    'Data uploading interval: minimum of 1 hour and maximum of 24 hours. Data recording interval: minimum of 1 minute and maximum of 60 minutes.',
  gecko_tenth_title: 'Historical Data Graphics',
  gecko_tenth_desc:
    'Historical data graphics of 24-hour and 30-day are available in Qingping IoT.',
  gecko_eleventh_title: 'Data Export',
  gecko_eleventh_desc:
    'Historical data up to 30 days could be exported as Excel files.',
  gecko_twelfth_title: 'No Data Loss During Connection Interrupted',
  gecko_twelfth_desc:
    'No need to worry about network interruption. Up to 2,880 sets{br} of data can be saved locally,  and the data would be uploaded when connection is back on. ',
  gecko_thirtyth_title:
    'Detailed APIs and Documents for {br}Customization Development',
  gecko_thirtyth_desc: 'Qingping Developer Platform: {br}{link}',
  gecko_fourteenth_title: 'Two Sockets with Large Spacing',
  gecko_fourteenth_desc:
    'There is a 21 mm space between the two-hole and the three-hole sockets to accommodate various plug sizes.',
  gecko_fifteenth_title: 'Flame Resistance Material,{br} Safe and Durable',
  gecko_fifteenth_desc:
    'The socket material has excellent flame retardancy and insulation, and is not easy to deform and fade.',

  gecko_size: '86 × 86 × 38.5 mm{br} (11.5 mm outside the wall)',
  gecko_color: 'White',
  g_distanse: 'Spacing of installation holes',
  gecko_distanse: '64 mm',
  gecko_temp_range: '-20 ~ 50℃',
  gecko_invironment:
    ' This product is not waterproof. Do not place this product in environments with humidity higher than 90% for long term use.',
  gecko_model: 'CGGK1',
  gecko_weight: '126.8 g',
  gecke_screen_type: 'Screen size',
  gecko_screen_size: '30 × 19 mm',
  gecko_rated_input: 'Rated voltage',
  gecko_rated_value: '250V AC',
  gecko_i_input: 'Rated current',
  gecko_i_value: '10A max',
  gecko_network_type: 'NB-IoT',
  gecko_material: 'Material',
  gecko_material_type: 'Flame Resistance PC',

  gecko_explain_01: 'The 86-type box is an 86 × 86 mm wall box.',
  gecko_explain_02:
    'When the environment temperature is 0 ~ 50℃, the accuracy of the temperature is ±0.8°C with load and ±0.5°C without load.',
  gecko_explain_03:
    'CMCC NB-IoT network coverage is required. NB-IoT SIM card includes a 6-year service plan of 50MB free data per year. It may vary in different batches.',
  gecko_explain_04:
    'Data of 30 days can be saved locally while the recording interval is 15 minutes.',

  // snow2
  sw2: 'Qingping Air Monitor 2',
  sw2_first_title: 'Qingping Air Monitor 2',
  sw2_first_desc_1: '7 metrics · Replaceable PM sensor · Automation · Touchscreen · Wi-Fi connectivity',
  sw2_first_desc_mob: '· 7 metrics {br} · Replaceable PM sensor {br} · Automation {br} · Touchscreen {br} · Wi-Fi connectivity',
  sw2_second_title: 'Monitor Comprehensively',
  sw2_second_desc: 'Track 7 major factors of indoor air quality.',
  sw2_second_temp: 'Temp & RH',
  sw2_second_co2: 'CO2',
  sw2_second_pm25: 'PM2.5 {br}& PM10',
  sw2_second_tvoc: 'eTVOC{sup}',
  sw2_second_noise: 'Noise',
  sw2_second_memo1:
    'Temperature and humidity are not only related to comfort, but also to health. Being in an environment with suboptimal temperature and humidity may cause sleep problems, even allergic reactions or respiratory diseases.',
  sw2_second_memo2:
    'High level CO2 would lead to drowsiness and usually indicate poor ventilation in the area, which also increases the risk of airborne virus infection.',
  sw2_second_memo3:
    'Fine dust particles such as PM2.5 and PM10, may infiltrate into our cardiovascular system and lead to serious health consequences. ',
  sw2_second_memo4:
    'TVOC refers to total volatile organic compounds, containing thousands of compounds. It may contain irritating gas and part of it may have negative effects on human health.',
  sw2_second_memo5:
    "Noise level has an impact on people's sleep quality and health. Constant noise may lead to insomnia, anxiety and increased stress. Prolonged exposure to excessive noise levels can cause hearing problems and may lead to deafness.",
  sw2_third_title: 'Take Action for Better Air',
  sw2_third_desc_1:
    'When the CO2 or eTVOC is high, open the window or turn on the air exchanger in time to improve the efficiency of working or studying.',
  sw2_third_desc_2:
    'Monitor particle pollution and turn on the air purifier in time.',
  sw2_third_desc_3:
    'Create a healthy and comfortable environment by automating devices such as air conditioning, heating, humidifiers, and dehumidifiers based on temperature and humidity.',
  sw2_fourth_title: 'Know the Air with a Glance',
  sw2_fourth_desc_1: 'Check the Air Data in Real-time',
  sw2_fourth_desc_1_memo: 'The 7 readings refresh every second.',
  sw2_fourth_desc_2: 'Multi-colored Indicators',
  sw2_fourth_desc_2_memo:
    'You can know the air quality immediately through the color of the indicators.',
  sw2_fourth_desc_3: 'Historical Data ',
  sw2_fourth_desc_3_memo:
    'Tap the screen to view the historical data of 24 hours and 30 days.',
  sw2_fourth_desc_4: 'Outdoor Air Quality',
  sw2_fourth_desc_4_memo:
    'Weather · Air quality index · UV index · No-driving plates',
  sw2_fifth_title: 'Aluminium Outer Casing, {br}Superior Texture',
  sw2_fifth_desc: 'White · Black',
  sw2_sixth_title: '4-inch "Retina" IPS Display',
  sw2_sixth_desc:
    'As exquisite as a high-end smartphone, with a large viewing angle and high contrast. Designed with an 18° tilt angle for easier viewing and operation.',
  sw2_sixth_spec_1: '254 ppi {spot} Capacitive touchscreen {br} Auto-brightness',
  // sw2_sixth_spec_2: 'Capacitive touchscreen',
  // sw2_sixth_spec_3: 'Auto-brightness',
  sw2_seventh_title: 'Just Touch, as Intuitive as a Smartphone',
  sw2_seventh_desc: 'Tap and slide, easy peasy.',
  sw2_eight_title: 'Clock · Alarm',
  sw2_eight_desc_1: 'Time {br}auto-sync',
  sw2_eight_desc_2: 'Multiple {br}alarms',
  sw2_eight_desc_3: 'Snooze',
  sw2_eight_desc_4: 'Customizable ringtone',
  sw2_ninth_title: 'Unparalleled Performance',
  sw2_ninth_desc: 'Fast · Accurate {br} Less drift in long-term use',
  sw2_ninth_memo1: '4-core A53 processor',
  sw2_ninth_memo2: 'Multi-mode compensation algorithm',
  sw2_ninth_memo3: 'Multiple high-accuracy sensors',
  sw2_tenth_title: 'Replaceable PM Sensor',
  sw2_tenth_desc:
    'The PM sensor is equipped with high-quality optical components and a magnetic levitation fan, enabling fast response and reliability, and providing accurate readings consistently. {br}The PM sensor connects to the body through contact points, and the back cover is attached to the body with magnetic attraction{sup}, making it easy to replace.',
  sw2_eleventh_title:
    'Innovative Vent Design, Makes the {br}Sensors Work More Efficiently',
  sw2_eleventh_desc:
    'Multiple-side vents {spot} Efficient {br} Accurate {spot} Quiet',
  sw2_eleventh_memo:
    'The vent holes have more contact with air, and will not be blocked easily.',
  sw2_twelveth_title: 'Works with Mi Home{sup}',
  sw2_twelveth_desc:
    'Compatible with many smart devices to make your home a smarter one. For example, when the PM2.5 is over 50, turn on the air purifier; when the humidity is lower than 30%, turn on the humidifier.',
  sw2_thirteenth_title: 'Works with Qingping+ App',
  sw2_thirteenth_desc:
    'Check out readings and recommendations at anytime, anywhere. Export historical data to Excel files.',
  sw2_fourteenth_title: 'Rechargeable Lithium Battery {br} USB-C Charging Port',
  sw2_fourteenth_desc:
    'You can plug it in USB power all the time. With an 1800mAh lithium battery built-in, the standby time reaches 4 hours. ',
  sw2_fifteenth_title: 'Upgradable, Keep Moving Forward',
  sw2_fifteenth_desc:
    'Like a smartphone, you can upgrade the device OTA to get new features and bug fixes.',
  sw2_sixteenth_memo_1:
    'The TVOC sensor used in this product automatically adjusts the measurement baseline according to the data of the past period, so this product displays eTVOC (equivalent TVOC), which is not an accurate TVOC absolute value, and is only for users to observe the relative change trend of environmental TVOC concentration.',
  sw2_sixteenth_memo_2:
    'Weather, AQI, UV and no-driving plates are only available in some cities.',
  sw2_sixteenth_memo_3:
    'Wi-Fi connection enables automatic synchronization with network time.',
  sw2_sixteenth_memo_4:
    'Multiple alarms to assist you with your daily routines, and you can set snooze mode and repeat rules individually.',
  sw2_sixteenth_memo_5:
    'When an alarm rings, tap "Snooze" on the screen or press the top button, it will ring again after 10 minutes. Tap "Stop" to turn off the alarm clock. ',
  sw2_sixteenth_memo_6:
    'Qingping Air Monitor 2 includes 8 ringtones, and you can upload an additional one through the  ',
  sw2_sixteenth_memo_6_1: 'Qingping+ app',
  sw2_sixteenth_memo_6_2: ' (this feature is not available yet).',
  sw2_sixteenth_memo_7:
    'Qingping Air Monitor 2  has built-in magnets, and the back cover has a built-in iron plate.',
  sw2_sixteenth_memo_8:
    "The product supports Mi Home's {br}Chinese mainland{br} server.",

  sw2_pm25_range: '0 ~ 999 μg/m³',
  sw2_temp_range: '-10 ~ 60℃',
  sw2_hum_range: '0 ~ 99%no condensation',
  sw2_tvoc_range: '0.005 ~ 9.999 mg/m³',
  sw2_co2_range: '400 ~ 9999 ppm',
  sw2_etvoc_range: 'VOC Index 0 ～ 500, or 0.005 ～ 9.999 mg/m³',
  sw2_noise_range: '36 ~ 95 dB',
  sw2_size: 'Size',
  sw2_color: 'White, Black',
  sw2_size_value_long: '85 mm',
  sw2_size_value_short: '104 mm',
  sw2_size_value_height: '74 mm',
  sw2_size_value: '74 × 85 × 104 mm',
  sw2_weight: 'Weight',
  sw2_weight_value: '250 g',
  sw2_screen_size_value: '4 inches',
  sw2_screen_resolution: 'Screen size',
  sw2_screen_resolution_value: '720 × 720',
  sw2_wifi_value: 'IEEE 802.11a/b/g/n/ac/ax',
  sw2_battery_type_value: 'Lithium lon Battery',
  sw2_battery_size: 'Capacity',
  sw2_battery_size_value: '1800mAh/3.7V',
  sw2_usb_port: 'Power port',
  sw2_usb_port_value: 'USB-C',
  sw2_rated_input: 'Power input',
  sw2_rated_input_value: '5V⎓1A',
  sw2_usb_charge: 'USB-C Cable',

  //snow2
  snow2_exterior: 'Design',
  snow2_range: 'Measurement Range',
  snow2_spec: 'Specs',
  snow2: 'Qingping Air Monitor 2',
  temp_range_snow2: '-10 ~ 60°C',
  hum_range_snow2: '0 ~ 99%RH（no condensation）',
  co2_range_snow2: '400 ~ 9999 ppm',
  pm25_pm10: 'PM2.5/PM10',
  pm25_pm10_range_snow2: '0 ~ 999 μg/m³',
  etvoc: 'eTVOC',
  etvoc_range_snow2: 'VOC Index 0 ~ 500, or 0.005 ~ 9.999 mg/m³',
  noise: 'Noise',
  noise_range_snow2: '36 ~ 95 dB',


}

export default {
  ...en,
  ...supportMi
}
