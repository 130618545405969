import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import s from './style.module.scss'
import Header from './../../header'
import Footer from './../../footer'
// import pic_specific_one from './../images/specific.png';
import pic_specific_two from './../images/specific02.png'
// import pic_specific_one_en from './../images/specific_en.png';
import pic_specific_two_en from './../images/specific02_en.png'
import pic_specific_three from './../images/specific03.png'
import pic_specific_four from './../images/specific04.png'
import common from '../../../../../locale/common'
import { Link } from 'react-router-dom'
import SupportApp from '../../a_components/SpecificationSupportApp'
class DoveSpecifications extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
    }
  }

  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title =
      common['dove_' + local] + ' - ' + common['specifications_' + local]
  }
  //隐藏Goose购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }
  render() {
    const { hideGooseChoice } = this.state
    const { location, locale } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.box}
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <Header
          location={location}
          language={this.state.language}
          path={path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <div className={s.specifications}>
          <div className={s.Gparam}>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="exterior" />
              </div>
              <div className={s.cell}>
                {/* <div className={s.sizeImg}><img src={ locale.indexOf('US') > -1 ? pic_specific_one_en : pic_specific_one } alt="" width="465" height="280" /></div> */}
                <div className={s.sizeImg}>
                  <div className={s.top_side}>
                    <div className={s.left_left}>
                      <div
                        className={
                          locale.indexOf('US') > -1 ? s.left_img_en : s.left_img
                        }
                      ></div>
                      <div className={s.left_bottom}></div>
                      <div className={s.bottom_num}>
                        <FormattedMessage id="do_size_long" />
                      </div>
                    </div>
                    <div className={s.left_right}></div>
                    <div className={s.right_num}>
                      <FormattedMessage id="do_size_short" />
                    </div>
                  </div>
                  <div className={s.top_top}>
                    <div
                      className={
                        locale.indexOf('US') > -1 ? s.top_img_en : s.top_img
                      }
                    ></div>
                    <div className={s.top_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="do_size_height" />
                    </div>
                  </div>
                </div>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_size" />
                    </span>
                    <FormattedMessage id="do_size" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_color" />
                    </span>
                    <FormattedMessage id="do_color" />
                  </li>
                </ul>
              </div>
            </div>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="range" />
              </div>
              <div className={s.cell}>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="mp_temp_range_label" />
                    </span>
                    <FormattedMessage id="do_temp_range" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="mp_humi_range_label" />
                    </span>
                    <FormattedMessage id="hum_range" />
                  </li>
                </ul>
                <p>
                  <FormattedMessage id="under_ninty" />
                </p>
              </div>
            </div>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="p_specifications" />
              </div>
              <div className={s.cell}>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="ph_name" />
                    </span>
                    <FormattedMessage id="dove" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_model" />
                    </span>
                    <FormattedMessage id="do_model" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_weight" />
                    </span>
                    <FormattedMessage id="do_weight" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="screen_size" />
                    </span>
                    <FormattedMessage id="do_screen_size" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="mp_ble_type" />
                    </span>
                    <FormattedMessage id="p_wifi_value" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_battery" />
                    </span>
                    <FormattedMessage id="do_battery_type" />
                  </li>

                </ul>
              </div>
            </div>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="other" />
              </div>
              <div className={s.cell}>
                <ul>
                  

                  <li style={{display:'flex'}}>
                  <span className={s.type} style={{display: 'flex', alignItems:' center'}}>
                    <FormattedMessage id="support_app" />
                  </span>
                  
                  <SupportApp supportApps={['mi_home', 'plus']}></SupportApp>
                </li>

                <li style={{ display:'flex', alignItems: 'flex-start', marginBottom: 0 }}>
                  <span className={s.type}>
                    <FormattedMessage id="remote_access" values={{ br: '' }} />
                  </span>
                  <span>
                    <FormattedMessage
                      id="mi_app_need_1"
                      values={{ br: <br /> }}
                    />
                    <Link
                      to="/bluetooth-gateway"
                      style={{ color: '#000', textDecoration: 'underline' }}
                    >
                      <FormattedMessage id="sparrow" />
                    </Link>
                    <FormattedMessage id="end" />
                    <br />
                    <span style={{ marginTop: 8, display: 'block' }}>
                      <FormattedMessage id="mi_app_need_2" />
                      <Link
                        to="/plus"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="cg_app" />
                      </Link>
                      <FormattedMessage
                        id="mi_app_need_3"
                        values={{ br: <br /> }}
                      />
                      <Link
                        to="/bluetooth-gateway"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="sparrow" />
                      </Link>
                      <FormattedMessage id="end" />
                    </span>
                  </span>
                </li>
                </ul>
              </div>
            </div>
            <div className={s.pack}>
              <div className={s.name}>
                <FormattedMessage id="p_pack" />
              </div>
              <div className={s.cell}>
                <div className={s.packImg}>
                  <img
                    src={
                      locale.indexOf('US') > -1
                        ? pic_specific_two_en
                        : pic_specific_two
                    }
                    width="120"
                    height="124"
                    alt=""
                  />
                  <p>
                    <FormattedMessage id="dove" />
                  </p>
                </div>
                <div className={s.packImg}>
                  <img
                    src={pic_specific_three}
                    width="120"
                    height="124"
                    alt=""
                  />
                  <p>
                    <FormattedMessage id="do_battery_type" />
                  </p>
                </div>
                <div className={s.packImg}>
                  <img
                    src={pic_specific_four}
                    width="120"
                    height="124"
                    alt=""
                  />
                  <p>
                    <FormattedMessage id="p_instructions" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const DoveSpecification = connect(mapStateToProps)(DoveSpecifications)

export default DoveSpecification
