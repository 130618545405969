import React, { Component } from "react";

import EnSPrivacy from "./en";
import ZhPrivacy from "./zh";
import ZhHantPrivacy from "./zh-Hant";
import IosEnPrivacy from "./ios_en";
import IosZhPrivacy from "./ios_zh";
import IosZhHantPrivacy from "./ios_zh-Hant";

class Privacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // init top hide
      language: "zh",
      appPlatform: "android",
    };
  }
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");
    const appPlatform = params.get("appPlatform");
    const theme = params.get("theme");
    function applyTheme(isDarkMode) {
      document.body.style.backgroundColor = isDarkMode ? '#000000' : '#ffffff';
      document.body.style.color = isDarkMode ? '#ffffff' : '#000000';
      setTimeout(() => {
        document.querySelectorAll('a').forEach(a => {
          a.style.color = isDarkMode ? '#ffffff' : '#000000';
          a.style.textDecoration = 'underline';
        });
      },1000)
    }
    
    const isDarkMode = theme 
      ? theme === 'dark' 
      : window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    applyTheme(isDarkMode);

    if (lang?.indexOf("en") > -1) {
      this.setState({
        language: "en",
      });
    } else if (lang?.indexOf("ant") > -1) {
      this.setState({
        language: "zh-Hant",
      });
    } else {
      this.setState({
        language: "zh",
      });
    }
    this.setState({
      appPlatform,
    });
  }

  render() {
    const { language, appPlatform } = this.state;
    return (
      <>
        {appPlatform === "ios" ? (
          <div>
            {language === "zh-Hant" ? (
              <IosZhHantPrivacy />
            ) : language === "en" ? (
              <IosEnPrivacy />
            ) : (
              <IosZhPrivacy />
            )}
          </div>
        ) : (
          <div>
            {language === "zh-Hant" ? (
              <ZhHantPrivacy />
            ) : language === "en" ? (
              <EnSPrivacy />
            ) : (
              <ZhPrivacy />
            )}
          </div>
        )}
      </>
    );
  }
}

export default Privacy;
