import React, { Component } from "react";

import s from "./airPrivacy.module.scss";

class iotPrivacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // init top hide
      display: false,
    };
  }
  componentDidMount() {
    document.getElementsByTagName("html")[0].style.fontSize = 41.4 + "px";
  }
  render() {
    return (
      <div className={s.iotPrivacy}>
        <div>
          <p>
            <b className={s.pt18}>隱私策略</b>
          </p>
          <p>
            <b className={s.pt12}>更新於 2025 年 2 月 8 日。</b>
          </p>
          <p>
            <b className={s.pt12}>歡迎使用青萍物聯 app。</b>
          </p>
          <p>
            當您使用本 app 時，您的手機機型資訊和 IP 地址會被自動發送到 app
            的伺服器，以便正常獲取並顯示 app 的內容和功能。
          </p>
          <p>
            如您註冊帳號，您用於註冊的手機號碼或電子郵件地址，或第三方登入方式的
            ID 將被 app 的伺服器記錄，用於辨識您的使用者身份。
          </p>
          <p>
            我們還將記錄您在 app
            中新增或共享的青萍設備的識別資訊、讀數和設置，以及在 app
            內創建或加入的團隊的相關資訊。這是您正常使用本 app 的功能所必需的。
          </p>
          <p>
            在您使用本 app 連接青萍設備時，app
            可能會請求手機的藍牙權限。在完成相關操作後，app
            會立即停止使用相關權限。
          </p>
          <p>本應用還集成了 Alipay SDK 以提供便捷的支付功能，並通過騰訊 Bugly 收集崩潰日誌，以便開發團隊快速定位和解決問題。</p>
          <p>
            如您在使用 app 或青萍設備時遇到問題，您可在“更多 {">"}{" "}
            聯繫我們”（或“更多 {">"} 高級 {">"} 獲取設備 Log”）中提交 app
            或青萍設備的 log，為了診斷問題，相關 log 將被記錄。
          </p>
          <p>如要在本 app 的伺服器上刪除您的數據，您可以銷毀您的帳號。</p>
          <p>
            如果本 app
            功能發生變化，我們收集的資訊可能會發生變化，屆時我們會更新此隱私協議。
          </p>
          <p>
            如您對本協議或您的個人資訊有任何疑問、意見或建議，請隨時發送郵件至{" "}
            <a href="mailto:support@qingping.co">support@qingping.co</a>{" "}
            與我們聯繫，我們將盡快為您解答。
          </p>
          <p>青萍科技（北京）有限公司</p>
        </div>
      </div>
    );
  }
}

export default iotPrivacy;
